import React, { useState } from 'react';

import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

const ReadInterview = (props) => {
  var id = 'application_id=' + props.id;
  if (props.application.interviews && props.application.interviews.nodes[0].id) {
    id = 'interview_id=' + props.application.interviews.nodes[0].id;
  }

  /* Example: works with either application_id, interview_id or both. 
  If only application_id; the first of the found interviews will be shown.
  TODO: Handle multiple interviews. 
  Base URL: 'https://hubert.ai/interview/viewer?'
  application_id: <Application oid>
  interview_id: <Interview oid>
  URL = base_url + application_id={application_id} + '&' + interview_id={interview_id}
  */
  // const baseUrl = 'http://web-hubert.ngrok.io/interview/viewer?';
  const baseUrl = 'https://interview.hubert.ai/viewer?';
  const url = baseUrl + id;

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop={true}
        keyboard={false}
        className="readinterview"
        closeButton
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Chat interview</Modal.Title>
        </Modal.Header> */}
        {/* <Modal.Body className="m-0 p-0"> */}
        <Iframe url={url} />
        {/* </Modal.Body> */}
      </Modal>
    </>
  );
};

export default ReadInterview;

const Iframe = ({ url }) => {
  const iframe = `<iframe height="100%" style="width: 100%;height: 100%;" scrolling="yes" title="Chat" src="${url}" frameborder="no" allowtransparency="true" allowfullscreen="true"/>`;
  return <div className="h-100 rounded " dangerouslySetInnerHTML={{ __html: url ? iframe : '' }} />;
};
