import * as ToggleGroup from '@radix-ui/react-toggle-group';
import './styles.scss';

const ToggleGroupRoot = ({ children, onChange, value }) => (
  <ToggleGroup.Root
    className="ToggleGroup"
    type="single"
    defaultValue="center"
    aria-label="Text alignment"
    onValueChange={onChange}
    value={value}
  >
    {children}
  </ToggleGroup.Root>
);

const ToggleGroupItem = ({ value, children }) => (
  <ToggleGroup.Item className="ToggleGroupItem" value={value} aria-label="Right aligned">
    {children}
  </ToggleGroup.Item>
);

export default {
  Root: ToggleGroupRoot,
  Item: ToggleGroupItem,
};
