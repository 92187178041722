import { forwardRef, useContext, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import { getScore } from '../common';
import ReadInterview from '../elements/ReadInterview';
import { GetTime } from '../elements/TimeIcon';
import LocaleContext from '../hooks/LocaleContext';

import AiDetectionIcon from '../AiDetectionIcon';
import BadgeDropdown from '../elements/BadgeDropdown';
import ReInvite from '../elements/ReInvite';

import Skeleton from 'react-loading-skeleton';
import CandidateDropdown from '../elements/CandidateDropdown';
import DownloadCv from '../elements/DownloadCv';
import OpenApplicationDialog from './ApplicationHeader/OpenApplicationDialog';
function isAiDetectedApplication(summaries) {
  if (summaries && Array.isArray(summaries)) {
    return summaries.some((x) => x && x.aiDetection && x.aiDetection.isAi);
  }
  return false;
}
const Header = forwardRef(
  (
    {
      application,
      selectedCallback,
      deleteApplication,
      editableStatus = true,
      readInterview = true,
      reInvite = true,
      candidateDropdown = true,
    },
    ref,
  ) => {
    const { userSettings } = useContext(LocaleContext);
    const [openApplicationShow, setOpenApplicationShow] = useState(false);

    const closeOpenApplication = () => setOpenApplicationShow(false);

    if (application) {
      var appliedDate,
        latestActivityDate = false;
      if (application) appliedDate = moment(application.createdAt);
      if (application && application.activityLog && application.activityLog.length > 0)
        latestActivityDate = application.activityLog[application.activityLog.length - 1].createdAt;

      const candidate = application && application.candidate;
      let candidateName = '';

      if (candidate.firstName && candidate.lastName) {
        candidateName = `${candidate.firstName} ${candidate.lastName}`;
      } else if (candidate.firstName) {
        candidateName = candidate.firstName;
      } else if (candidate.lastName) {
        candidateName = candidate.lastName;
      }

      return (
        <Row className="swiper-header mx-0 pl-3" ref={ref} id="swiper-header">
          <div className="score">
            <div className="my-auto">
              {application && getScore({ application: application, threshold: application.job.threshold, size: 'xl' })}
            </div>
          </div>
          <Col className="">
            <Row className="h-100">
              <Col className="my-auto m-0 d-flex justify-content-between">
                <div>
                  {application && application.candidate && !userSettings.settings.anonymized && (
                    <span className="text-capitalize">
                      <h1 className="text-truncate">{candidateName}</h1>
                    </span>
                  )}

                  <div className="swiper-contact d-flex">
                    <div>
                      {application && application.candidate && application.candidate.email && (
                        <>
                          <FontAwesomeIcon icon={['far', 'envelope']} className="mr-1" />
                          <span className="mr-2">{application.candidate.email}</span>
                        </>
                      )}
                    </div>

                    <div>
                      {application && application.candidate && application.candidate.phone && (
                        <>
                          <FontAwesomeIcon icon={['far', 'phone-rotary']} className="mr-1" />
                          <span>{application.candidate.phone}</span>
                        </>
                      )}
                    </div>
                  </div>

                  {(!application.candidate || userSettings.settings.anonymized) && <h1>Applicant</h1>}
                  {application.candidate && (
                    <div className="d-flex">
                      <BadgeDropdown
                        initStatus={application.status}
                        statuscallback={selectedCallback}
                        className="clickable header-dropdown"
                        editableStatus={editableStatus}
                      />
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip id={`tooltip`}>{`Latest activity ${moment(latestActivityDate).format(
                            'YYYY-MM-DD HH:mm',
                          )}`}</Tooltip>
                        }
                      >
                        <div className="time">
                          <FontAwesomeIcon icon={['far', 'spinner']} fixedWidth className="my-auto" />
                          {GetTime(latestActivityDate)}
                          {/* <Moment toNow ago date={ago} interval={10000} /> */}
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip id={`tooltip`}>{`Applied ${moment(appliedDate).format(
                            'YYYY-MM-DD HH:mm',
                          )}`}</Tooltip>
                        }
                      >
                        <div className="time mx-2">
                          {application && <FontAwesomeIcon icon={['far', 'clock']} fixedWidth className="my-auto" />}{' '}
                          {GetTime(appliedDate)}
                        </div>
                      </OverlayTrigger>
                    </div>
                  )}
                </div>

                <div className="my-auto m-0 d-flex justify-content-between">
                  {isAiDetectedApplication(application._summaries ?? application.summaries.nodes) ? (
                    <AiDetectionIcon
                      iconSize={'2x'}
                      tooltipText="Applicant may have used an AI to answer some questions in the interview."
                    />
                  ) : null}

                  <span>
                    {readInterview === true ? (
                      <ReadInterview id={application.id} application={application} variant="secondary" className="ml-2">
                        <FontAwesomeIcon icon={['far', 'align-left']} fixedWidth className="my-auto" />
                      </ReadInterview>
                    ) : (
                      <></>
                    )}
                    {reInvite === true && (application.stage === null || application.stage < 2) && (
                      <ReInvite application={application} className="ml-1 ml-2" />
                    )}
                    {application && application.openApplicationSummary && (
                      <>
                        <OverlayTrigger delay={300} placment={'bottom'} overlay={<Tooltip>Share</Tooltip>}>
                          <Button
                            onClick={() => setOpenApplicationShow(!openApplicationShow)}
                            variant="secondary"
                            className="ml-1 ml-2"
                          >
                            <FontAwesomeIcon icon={['far', 'share-from-square']} fixedWidth className="my-auto" />
                          </Button>
                        </OverlayTrigger>

                        <OpenApplicationDialog
                          onHide={() => closeOpenApplication()}
                          show={openApplicationShow}
                          openApplication={application.openApplicationSummary}
                          applicationId={application.id}
                        />
                      </>
                    )}

                    {application.documents &&
                      application.documents.map((doc) => (
                        <DownloadCv key={doc.id} applicationId={application.id} fileId={doc.id} className="ml-1 ml-2" />
                      ))}
                    {candidateDropdown && (
                      <CandidateDropdown
                        application={application}
                        deleteApplication={deleteApplication}
                      ></CandidateDropdown>
                    )}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    } else return <SkeletonHeader />;
  },
);

Header.displayName = 'Header';

export default Header;

const SkeletonHeader = () => {
  return (
    <Row className="swiper-header mx-0 pl-3">
      <div className="score">
        <span className="my-auto">
          <Skeleton width="4rem" height={'4rem'} circle={true} />
        </span>
      </div>
      <Col className="">
        <Row className="h-100 m-0 ">
          <Col className="my-auto m-0 p-0 d-flex justify-content-between">
            <span>
              <span className="text-capitalize user-select-none">
                <h1 className="text-truncate">
                  <Skeleton width="8rem" />
                  <Skeleton width="8rem" />
                </h1>
              </span>

              <>
                <span className="float-left">
                  <Skeleton width="5rem" height="0.9rem" />
                </span>
                <span className="time mx-2">
                  <Skeleton width="1rem" height="1rem" circle />
                  {/* <FontAwesomeIcon icon={['far', 'clock']} fixedWidth className="my-auto" /> */}
                  <span className="mx-1" />
                  <Skeleton width="2rem" />
                </span>
                <span className="time">
                  <Skeleton width="1rem" height="1rem" circle />
                  {/* <FontAwesomeIcon icon={['far', 'clock']} fixedWidth className="my-auto" /> */}
                  <span className="mx-1" />
                  <Skeleton width="2rem" />
                </span>
              </>
            </span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
