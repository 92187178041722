import BPagination from 'react-bootstrap/Pagination';

const InnerPagination = ({ pages, currentPage, max, transform }) => {
  const total = pages.length;

  const getPages = (startIndex = 0, endIndex = total) => {
    return pages.slice(startIndex, endIndex).map((page, index) => transform(page, startIndex + index));
  };

  if (total <= max) return getPages();

  const lastPage = total - 1;
  const width = Math.floor(max / 2) - 2;
  const padLeft = Math.min(0, total - (currentPage + width + 2));
  const startAt = Math.max(2, currentPage - width + padLeft);
  const endAt = Math.min(startAt + max - 4, lastPage - 1);

  return [
    ...getPages(0, 1),
    startAt === 2 ? getPages(1, 2) : <BPagination.Ellipsis key={'ellipsis-1'} />,
    ...getPages(startAt, endAt),
    endAt === lastPage - 1 ? getPages(lastPage - 1, lastPage) : <BPagination.Ellipsis key={'ellipsis-2'} />,
    ...getPages(lastPage),
  ];
};

const Pagination = ({ pages, items, selectedIndex, onChange, style }) => {
  const onPageChange = (event, selectedPageIndex) => {
    if (onChange) {
      onChange(event, pages[selectedPageIndex]);
    }
  };

  const transform = (pageNum, currPageIndex) => {
    const selectedPage = pages[selectedIndex];
    return (
      <BPagination.Item
        onClick={(event) => onPageChange(event, currPageIndex)}
        active={pageNum === selectedPage}
        key={'page-' + currPageIndex}
      >
        {pageNum}
      </BPagination.Item>
    );
  };

  return (
    <BPagination style={style}>
      <BPagination.First onClick={(event) => onPageChange(event, 0)} />
      <InnerPagination pages={pages} currentPage={selectedIndex} max={13} transform={transform} />
      <BPagination.Last onClick={(event) => onPageChange(event, pages.length - 1)} />
    </BPagination>
  );
};

export default Pagination;
