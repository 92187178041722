import React, { useState, useEffect, useRef, useCallback } from 'react';
import Autosuggest from 'react-autosuggest';

import { useLazyQuery, gql } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, InputGroup, ListGroup, Tooltip, OverlayTrigger } from 'react-bootstrap';

var _ = require('lodash');

const SEARCH_QUERY = gql`
  query ($query: String!, $type: SearchCategories!) {
    search(input: { query: $query, type: $type }) {
      ... on Job {
        jobId: id
        title
        location {
          name
        }
      }

      ... on Candidate {
        id
        firstName
        lastName
        email
        applications {
          nodes {
            id
            score
            job {
              title
              threshold
            }
          }
        }
      }
    }
  }
`;

const Searchbar = ({ className, history }) => {
  const [value, setValue] = useState('');
  const [focus, setFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef();
  const searchbarRef = useRef();

  const [searchCategory, setSearchCategory] = useState('All');
  const [search, { loading, data, refetch }] = useLazyQuery(SEARCH_QUERY, {
    fetchPolicy: 'network-only',
  });
  const [suggestion, setSuggestion] = useState([]);

  useEffect(() => {
    data && setSuggestion(data.search);
  }, [data]);

  const setCategory = (val) => {
    setSearchCategory(val);

    if (value.length > 0) {
      search({ variables: { query: value, type: val.toUpperCase() } });
    }
  };

  const loadSuggestions = (val) => {
    if (val.target.value.length > 0) {
      search({ variables: { query: val.target.value, type: searchCategory.toUpperCase() } });
    } else {
      setSuggestion([]);
    }
  };

  const debounceLoad = useCallback(_.debounce(loadSuggestions, 500), []);

  const debouncedLoadSuggestions = (val) => {
    if (val.target.value.length > 0) {
      setValue(val.target.value);
      setIsLoading(true);
      debounceLoad(val);
    } else {
      setSuggestion([]);
      debounceLoad(val);
    }
  };

  const closeDropdown = () => {
    setFocus(false);
  };

  const Candidate = ({ candidate, onSelect }) => {
    const navigateToCand = () => {
      history.push('/candidates/' + candidate.id);
      onSelect();
    };

    return (
      <ListGroup.Item className="m-0 px-2 d-flex text-black clickable" onClick={() => navigateToCand()}>
        <Col className="m-0 p-0">
          <div className="font-weight-boldd">
            {candidate.firstName} {candidate.lastName}
          </div>
          <div className="m-0 p-0 font-weight-light">{candidate.email}</div>
        </Col>
        <Col className="m-0 p-0" sm={candidate.applications.length >= 3 ? candidate.applications.length : 2}>
          {candidate.applications.nodes.map((app) => {
            return (
              <OverlayTrigger
                delay={500}
                key={`overlay-${app.id}`}
                placment={'bottom'}
                overlay={<Tooltip key={`tooltip-name-${app.id}`}>{`${app.job.title}`}</Tooltip>}
              >
                <div
                  className={'name-pill float-right ' + (app.score >= app.job.threshold ? 'purple' : 'grey')}
                  key={app.id}
                >
                  {app.score ? parseInt(app.score) : '-'}
                </div>
              </OverlayTrigger>
            );
          })}
          {/* <pre className="m-0 p-0">{email}</pre> */}
        </Col>
      </ListGroup.Item>
    );
  };

  const Job = ({ job, onSelect }) => {
    const navigateToJob = () => {
      history.push(`/job/${job.jobId}`);
      onSelect();
    };

    return (
      <ListGroup.Item className="m-0 px-2 d-flex text-black clickable" onClick={() => navigateToJob()}>
        <Col className="m-0 p-0">
          <div className="font-weight-boldd">{job.title}</div>
          <div className="m-0 p-0 font-weight-light">{job.location.name}</div>
        </Col>
        <Col sm="2" className="p-0 m-0">
          <div className="job-result float-right">
            <FontAwesomeIcon className=" color white" icon={['far', 'briefcase']} />
          </div>
        </Col>
      </ListGroup.Item>
    );
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showDropdown && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setShowDropdown(false);
      }

      if (focus && searchbarRef.current && !searchbarRef.current.contains(e.target)) {
        setFocus(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showDropdown, focus]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <div ref={searchbarRef} className={(focus ? 'w-75' : 'w-50') + ' search-bar-container'}>
      <div className="search-bar-wrapper">
        <div ref={dropdownRef} className="search-bar-select">
          <a className="btn-dropdown-link" onClick={() => setShowDropdown(!showDropdown)} href="#">
            <span>{searchCategory}</span>
          </a>
          <div
            ref={dropdownRef}
            className={`btn-dropdown-options ${showDropdown ? 'btn-dropdown-options-visible' : null}`}
          >
            {showDropdown && (
              <ul>
                <li
                  onClick={() => {
                    setCategory('All');
                    setShowDropdown(false);
                  }}
                >
                  <span>All</span>
                </li>
                <li
                  onClick={() => {
                    setCategory('Job');
                    setShowDropdown(false);
                  }}
                >
                  <span>Job</span>
                </li>
                <li
                  onClick={() => {
                    setCategory('Candidate');
                    setShowDropdown(false);
                  }}
                >
                  <span>Candidate</span>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className="vertical-divider"></div>
        <input
          ref={searchbarRef}
          onFocus={() => setFocus(true)}
          onChange={debouncedLoadSuggestions}
          className="search-bar-input"
          type="text"
          placeholder="Search..."
        />
      </div>
      {focus && (
        <div className="search-results">
          <ListGroup variant="flush">
            {!isLoading && data && suggestion.length === 0 && (
              <ListGroup.Item>
                <span>No results found</span>
              </ListGroup.Item>
            )}
            {suggestion &&
              suggestion.map((res, index) => {
                if (res.__typename === 'Candidate') {
                  return <Candidate key={index} candidate={res} onSelect={closeDropdown} />;
                } else if (res.__typename === 'Job') {
                  return <Job key={index} job={res} onSelect={closeDropdown} />;
                }
              })}
            {isLoading && (
              <ListGroup.Item>
                <FontAwesomeIcon
                  icon={['far', 'spinner']}
                  spin
                  fixedWidth
                  size="lg"
                  className="color dark-grey d-block mx-auto"
                />
              </ListGroup.Item>
            )}
          </ListGroup>
        </div>
      )}
    </div>
  );
};

export default Searchbar;
