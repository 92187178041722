import React, { useState, useEffect } from 'react';
import { Col, Row, Spinner, Container } from 'react-bootstrap';
import { useMutation, gql } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { CONNECT_TO_LEVER } from '../../../../behavior/queries/integration';

const Integrations = () => {
  const { search } = useLocation();
  const [loading, setLoading] = useState(false);
  const [leverError, setLeverError] = useState('');
  const [integrateLever] = useMutation(CONNECT_TO_LEVER);

  const integration = async (code, clientId) => {
    setLoading(true);
    try {
      const response = await integrateLever({
        variables: {
          code: code,
          clientId: clientId,
        },
      });
      if (response.data.integrateLever.__typename !== 'LeverIntegrationSuccess') {
        if (window.opener !== null) {
          window.opener.onError(response.data.integrateLever.message);
          setLoading(false);
          window.close();
        }
        setLoading(false);
        setLeverError(response.data.integrateLever.message);
      }
      if (response.data.integrateLever.success === 'OK') {
        if (window.opener !== null) {
          window.opener.onSuccess(true);
          setLoading(false);
          window.close();
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      setLeverError(error.data.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (new URLSearchParams(search).get('error') !== null) {
      const error = new URLSearchParams(search).get('error');
      const errorDescription = new URLSearchParams(search).get('error_description');
      setLeverError(errorDescription);
      setLoading(false);
    } else {
      const code = new URLSearchParams(search).get('code');
      if (window.opener !== null) {
        const clientId = window.opener.onOpen();
        integration(code, clientId);
      }
    }
  }, []);

  return (
    <span>
      <Container>
        {loading && (
          <div className="centerSpinner">
            <Spinner className="mx-auto" animation="border" role="status" />
          </div>
        )}
        {!loading && (
          <Row className="border rounded">
            <Col className="pb-3 pt-3">
              <h2 className="text-truncate pb-3">{leverError}</h2>
            </Col>
          </Row>
        )}
      </Container>
    </span>
  );
};

export default Integrations;
