import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Dropdown, ListGroup } from 'react-bootstrap';
import { useLazyQuery, useQuery, gql, NetworkStatus, useMutation, useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';

export const PINNED_JOBS_QUERY = gql`
  query pinnedJobs {
    pinnedJobs: viewer {
      id
      pinnedJobs {
        id
        title
        pinned
        location {
          name
        }
      }
    }
  }
`;

const PinnedJobsList = ({ pinnedJobs }) => {
  const history = useHistory();

  const gotoJob = (id) => {
    history.push('/job/' + id);
  };

  if (pinnedJobs.length === 0) {
    return (
      <Dropdown.Item key="disabled" disabled>
        <span>No pinned jobs.</span>
      </Dropdown.Item>
    );
  }

  return pinnedJobs.map((job, index) => (
    <Dropdown.Item
      key={index}
      eventKey={job.id}
      onClick={() => {
        gotoJob(job.id);
      }}
    >
      <div>
        {job.title}{' '}
        {job.location.name && (
          <Badge className="d-inline-block" variant="info">
            {job.location.name}
          </Badge>
        )}
      </div>
    </Dropdown.Item>
  ));
};

const PinnedJobs = () => {
  const [pinnedJobs, setPinnedJobs] = useState([]);

  const { data, loading, refetch, networkStatus } = useQuery(PINNED_JOBS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data && data.pinnedJobs?.pinnedJobs) {
      setPinnedJobs(sortJobs(data.pinnedJobs.pinnedJobs));
    }
  }, [data]);

  const sortJobs = (jobs) => {
    const tempArray = [...jobs];

    tempArray.sort((a, b) => {
      if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1;
      }
      if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1;
      }

      return 0;
    });

    return tempArray;
  };

  const handleToggle = (isOpen, event) => {
    if (isOpen) {
      refetch();
    }
  };

  return (
    <div className="pinned-jobs-menu">
      <Dropdown onToggle={handleToggle} alignRight>
        <Dropdown.Toggle variant="black">
          <FontAwesomeIcon icon={['fas', 'thumbtack']} fixedWidth className="my-auto" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="modal">
          <Dropdown.Header className="header d-flex">
            <h2>Pinned jobs</h2>
            {loading && (
              <FontAwesomeIcon
                icon={['far', 'spinner']}
                spin
                fixedWidth
                size="lg"
                className="position-absolute"
                style={{ right: '0.5rem' }}
              />
            )}
          </Dropdown.Header>
          <PinnedJobsList pinnedJobs={pinnedJobs} />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default PinnedJobs;
