import React from 'react';
import { Helmet } from 'react-helmet';

const Title = ({ title }) => {
  return (
    <Helmet>
      <title>{title ? 'Hubert | ' + title : 'Hubert'}</title>
    </Helmet>
  );
};

export default Title;
