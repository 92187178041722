import { useContext } from 'react';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import { getAccessToken } from '../components/accesstoken';
import LocaleContext from './hooks/LocaleContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { userSettings, setLoggedIn } = useContext(LocaleContext);

  var token = getAccessToken();
  const path = useHistory().location.pathname;
  const search = useLocation().search;
  var redirectTo = encodeURIComponent(path + search);

  if (token === '' || token === undefined) {
    setLoggedIn(false);
    return <Redirect to={'/signin?redirect=' + redirectTo} />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        userSettings.loggedIn && token ? <Component {...props} /> : <Redirect to={'/signin?redirect=' + redirectTo} />
      }
    />
  );
};

export default PrivateRoute;
