import { gql, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'sonner';
import CopyLink from '../../elements/CopyLink/CopyLink';
import { Divider } from '../../elements/Divider';
import ToggleGroup from '../../elements/ToggleGroup/ToggleGroup';
import './OpenApplicationDialog.scss';

const extendOpenApplicationMutation = gql`
  mutation extendOpenApplicationSummary($applicationId: String!, $date: Date!) {
    extendOpenApplicationSummary(applicationId: $applicationId, date: $date) {
      __typename

      ... on OpenApplicationSummary {
        expiry
      }

      ... on ExtendOpenApplicationSummaryError {
        code
      }
    }
  }
`;

const OpenApplicationDialog = ({ show, onHide, openApplication, applicationId }) => {
  let { expiry, isDisabled } = openApplication;

  const [expiryDate, setExpiryDate] = useState(new Date(expiry));
  const [extendSummaryLinkError, setExtendSummaryLinkError] = useState('');

  const now = new Date();
  const isExpired = new Date() > expiryDate;
  const isExpiringWithinAWeek = expiryDate - now > 0 && expiryDate - now <= 1000 * 60 * 60 * 24 * 7;
  const [extendOpenApplication, { data: extendOpenApplicationData, loading: extendOpenApplicationLoading }] =
    useMutation(extendOpenApplicationMutation, {
      update(cache, { data: { extendOpenApplicationSummary } }) {
        if (extendOpenApplicationSummary.expiry) {
          cache.modify({
            id: cache.identify({ __typename: 'Application', id: applicationId }),
            fields: {
              openApplicationSummary() {
                return {
                  isDisabled: true,
                  expiry: extendOpenApplicationSummary.expiry,
                };
              },
            },
          });

          const newExpiryDate = new Date(extendOpenApplicationSummary.expiry);

          setExtendSummaryLinkError(null);
          setExpiryDate(newExpiryDate);
          toast.success(`The open summary has been extended until ${newExpiryDate.toLocaleString()}.`);
        } else if (extendOpenApplicationSummary.code === 'EXPIRY_DATE_TOO_FAR') {
          setExtendSummaryLinkError('The expiry date cannot be extended beyond 180 days from now.');
        }
      },
    });

  const [extendDuration, setExtendDuration] = useState('1h');

  const handleDuration = (duration) => {
    if (duration !== '') {
      setExtendDuration(duration);
    }
  };

  const handleExtendOpenApplication = async () => {
    const regex = /^(\d+)([dh])$/i; // 1d, 1h
    const match = extendDuration.match(regex);
    const now = new Date();

    if (!match) {
      throw new Error('Invalid duration format.');
    }

    const amount = parseInt(match[1], 10);
    const unit = match[2].toLowerCase();

    let futureDate = new Date(expiryDate);

    if (futureDate < now) {
      futureDate = now;
    }

    switch (unit) {
      case 'd':
        futureDate.setDate(futureDate.getDate() + amount);
        break;
      case 'h':
        futureDate.setHours(futureDate.getHours() + amount);
        break;
      default:
        throw new Error('Invalid duration unit.');
    }

    await extendOpenApplication({
      variables: {
        applicationId,
        date: futureDate.toISOString(),
      },
    });
  };

  const formattedDatePart = expiryDate.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  const formattedTimePart = expiryDate.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  });

  return (
    <Modal show={show} size="md" onHide={() => onHide()} centered>
      <Modal.Header closeButton>Open Application Summary</Modal.Header>
      <Modal.Body>
        <p className="secondary-text">
          The open application summary is used for sharing a candidate's application with an external party without
          having to invite them to the recruiter app. The application is available to anyone with the link.
        </p>
        <CopyLink
          className=""
          url={`${window.location.protocol}//${window.location.host}/application/share/${applicationId}`}
        />

        <Divider variant="middle" />
        <h3>Expiration date</h3>

        <div className="open-summary-details-wrapper mb-3">
          <div
            className={`open-summary-details-text ${isExpired ? 'expired' : ''} ${
              isExpiringWithinAWeek ? 'expires-soon' : ''
            }`}
          >
            <FontAwesomeIcon style={{ marginRight: '0.5rem' }} icon={['far', 'calendar']} />
            <span>
              {formattedDatePart} {formattedTimePart}
            </span>
          </div>
          {isExpiringWithinAWeek && <span className="error-message warning">The link will expire soon.</span>}
          {isExpired && <div className="error-message expired">The link has expired.</div>}
        </div>
        <h3>Extend validity period</h3>
        <ToggleGroup.Root value={extendDuration} onChange={handleDuration}>
          <ToggleGroup.Item value="1h">1 hour</ToggleGroup.Item>
          <ToggleGroup.Item value="6h">6 hours</ToggleGroup.Item>
          <ToggleGroup.Item value="12h">12 hours</ToggleGroup.Item>
          <ToggleGroup.Item value="1d">1 day</ToggleGroup.Item>
          <ToggleGroup.Item value="7d">7 days</ToggleGroup.Item>
          <ToggleGroup.Item value="30d">30 days</ToggleGroup.Item>
          <ToggleGroup.Item value="90d">90 days</ToggleGroup.Item>
          <div className="open-summary__extend-error">{extendSummaryLinkError}</div>
        </ToggleGroup.Root>
        <button onClick={handleExtendOpenApplication} className="open-summary-extend-button">
          {extendOpenApplicationLoading ? <FontAwesomeIcon icon={['fas', 'spinner']} spin /> : 'Add more time'}
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default OpenApplicationDialog;
