import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BackToHubert = () => {
  return (
    <div className="inline-block" style={{ position: 'absolute', right: '30px', top: '10px' }}>
      <a href="https://app.hubert.ai" alt="Back to index" className="text-dark">
        <span className="align-middle">Back to Hubert.ai</span>
        <span className="align-middle" style={{ alignItems: 'center' }}>
          <FontAwesomeIcon icon={['far', 'times-circle']} fixedWidth className="mx-2" style={{ fontSize: '2rem' }} />
        </span>
      </a>
    </div>
  );
};

export default BackToHubert;
