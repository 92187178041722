import { gql, useMutation } from '@apollo/client';
import isDeepEqual from 'fast-deep-equal/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import LocaleContext from '../../hooks/LocaleContext';
import BackToHubert from '../../navbar/BackToHubert';

import { setAccessToken } from '../../accesstoken';

import Title from '../../elements/Title';

import { initializeApp } from 'firebase/app';
import { SAMLAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';

const LOGIN = gql`
  mutation Login($email: String!, $password: String!, $provider: String) {
    login(email: $email, password: $password, provider: $provider) {
      __typename

      ... on LoginResponse {
        accessToken
        user {
          firstName
          lastName
        }
      }

      ... on LoginError {
        code
      }

      ... on UserNotActivated {
        email
      }
    }
  }
`;

const firebaseApp = {
  _app: undefined,
  get app() {
    return (this._app = initializeApp({
      apiKey: 'AIzaSyD-mGRXlVBuGhEPIKMeFfBC3FhYzJBxswk',
      projectId: 'production-recruiter-app',
      authDomain: `production-recruiter-app.firebaseapp.com`,
    }));
  },
  getAuth() {
    return getAuth(this.app);
  },
  async signInWithPopup(pid) {
    const saml = new SAMLAuthProvider(pid);
    const auth = firebaseApp.getAuth();
    return await signInWithPopup(auth, saml);
  },
};

const getProviderParams = async (pid, email, password) => {
  console.info('getProviderParams', { pid, email, password });
  if (pid) {
    const r = await firebaseApp.signInWithPopup(pid);
    return {
      email: r.user.email,
      password: await r.user.getIdToken(),
      provider: pid,
    };
  }
  return { email, password, provider: undefined };
};

const SignIn = ({ pid }) => {
  const history = useHistory();
  let query = useQuery();

  pid ??= query.get('pid');
  const isFederatedLogin = !!pid;

  console.info({ isFederatedLogin: pid });

  const { userSettings, setLoggedIn } = useContext(LocaleContext);
  const prevParams = useRef(history);

  const [validated, setValidated] = useState(false);
  const [checkValidated, setCheckValidated] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState();

  useEffect(() => {
    if (!isDeepEqual(prevParams.current, history)) {
      if (userSettings.loggedIn) query.get('redirect') ? history.push(query.get('redirect')) : history.push('/');
      prevParams.current = history;
    }
  }, [userSettings, history, query]);

  const [login, { loading }] = useMutation(LOGIN, {
    onCompleted(data) {
      if (loading) console.log('Loading.....');
    },
  });

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    setCheckValidated(true);
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(false);
    } else {
      setValidated(true);
      console.info('handleSubmit', { pid, email, password });
      getProviderParams(pid, email.trim().toLowerCase(), password)
        .then((v) => login({ variables: v }))
        .then((response) => {
          if (response && response.data && response.data.login) {
            const typename = response.data.login.__typename;

            if (typename === 'LoginResponse') {
              setLoginError();
              setAccessToken(response.data.login.accessToken);
              setLoggedIn(true);
              query.get('redirect') ? history.push(query.get('redirect')) : history.push('/jobs');
            } else if (typename === 'UserNotActivated') {
              setLoginError(
                <span>
                  Your account has not been activated. Please check your email for the activation email or{' '}
                  <Link to={{ pathname: '/forgot', state: { email, state: 'not-activated' } }}>click here</Link> to
                  request a new one.
                </span>,
              );
            } else if (typename === 'LoginError') {
              setLoginError('Invalid email or password.');
            }
          }
        })
        .catch((err) => {
          console.error(err);
          errorSignOn(err);
        });
    }
  };
  const errorSignOn = (error) => {
    console.log('errorSignOn error', error);
    const errors = error.networkError?.result?.errors;
    const message = (errors && errors[0]?.message) ?? error.message;
    setLoggedIn(false);
    setPassword('');
    setLoginError(message);
  };

  const checkForm = (event) => {
    const form = event.currentTarget;
    form.checkValidity() ? setValidated(true) : setValidated(false);

    if (event.target.id === 'email') {
      setEmail(event.target.value);
    } else if (event.target.id === 'current-password') {
      setPassword(event.target.value);
    } else console.log(event);
  };

  return (
    <Container fluid className="vh-100">
      {/* <Loader loading={loading} /> */}
      <Title title={'Sign-In'} />

      <BackToHubert />
      <Row className="d-flex align-items-center justify-content-center h-100 ">
        <Col lg={5} s={12} className="p-xs-0 m-1 p-md-3 sign-in">
          <Row>
            <Col className="d-flex justify-content-center align-items-center text-center">
              <div className="pb-4 w-75" style={{ maxWidth: '25rem' }}>
                <div className="">
                  <img src="/logo/hubert_logo_lockup_dark@2x.png" className="logo" alt="Hubert Logo" />
                  <h2>Sign in to Hubert</h2>
                </div>
                <Form noValidate validated={checkValidated} onSubmit={handleSubmit} autoComplete="on">
                  {loginError && (
                    <Alert variant="danger">
                      {/* <Alert.Heading>Error..</Alert.Heading> */}
                      {loginError}
                      {/* <hr />
                      <div className="">
                        <pre>{loginError}</pre>
                      </div> */}
                    </Alert>
                  )}
                  <Form.Group className="my-4">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      id="email"
                      required={!isFederatedLogin}
                      hidden={isFederatedLogin}
                      value={email}
                      onChange={checkForm}
                      className="text-center"
                    />
                    <Form.Control.Feedback type="invalid">Please enter a valid email.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="my-4">
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      required={!isFederatedLogin}
                      hidden={isFederatedLogin}
                      name="current-password"
                      id="current-password"
                      value={password}
                      onChange={checkForm}
                      className="text-center"
                    />
                    <Form.Control.Feedback type="invalid">Invalid password.</Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    type="submit"
                    block
                    className="my-4"
                    variant={validated ? 'primary' : 'secondary'}
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                      </>
                    ) : (
                      'Sign in'
                    )}
                  </Button>
                  <NavLink to="/forgot" className="text-decoration-none">
                    <Button variant="secondary" block>
                      Trouble signing in?
                    </Button>
                  </NavLink>
                  <span className="old-hubert">
                    Looking for{' '}
                    <a href="https://legacy.hubert.ai/" target="_blank" rel="noreferrer">
                      old Hubert
                    </a>
                    ?
                  </span>
                </Form>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SignIn;
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
