import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const AiDetectionIcon = ({
  iconSize = '1x',
  tooltipText = 'This answer is likely to have been generated by an AI.',
}) => {
  return (
    <OverlayTrigger placement="auto" overlay={<Tooltip>{tooltipText}</Tooltip>}>
      <span className="m-auto text-warning">
        <FontAwesomeIcon icon={['fas', 'microchip-ai']} size={iconSize} className="my-auto" />
      </span>
    </OverlayTrigger>
  );
};
export default AiDetectionIcon;
