import React from 'react';
import { Row, Col } from 'react-bootstrap';

import Skeleton from 'react-loading-skeleton';

const PageHeader = (props) => {
  return <Row className={'header-row d-flex justify-content-between ' + props.className}> {props.children}</Row>;
};
export default PageHeader;

const Title = (props) => {
  return (
    <Col className="mx-0 px-0">
      <h1>{props.children}</h1>
    </Col>
  );
};

const Swiper = ({ className, loading, job }) => {
  const locationName = job.location?.name || job.location?.city || job.location?.country;

  if (!loading)
    return (
      <span className={'my-auto ' + className}>
        <h1 className="swiper-count ">{job.applications.totalCount}</h1>
        <span className="float-left">
          <h4>{`Job${locationName ? ` - ${locationName}` : ''}`}</h4>
          <h3 className="float-left">{job.title}</h3>
        </span>
      </span>
    );
  else
    return (
      <span className="my-auto">
        <h1 className="swiper-count ">
          <Skeleton width="3rem" />
        </h1>
        <span className="float-left">
          <h4>
            Job - <Skeleton />
          </h4>
          <h3 className="float-left">
            <Skeleton width="20rem" />
          </h3>
        </span>
      </span>
    );
};

const Buttons = (props) => {
  if (props.children) return <span className="ml-auto my-auto swiper-icon-wrapper">{props.children}</span>;
  else return <span></span>;
};

PageHeader.Title = Title;
PageHeader.Swiper = Swiper;
PageHeader.Buttons = Buttons;
