import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Tab, Col, Row, Nav } from 'react-bootstrap';

import LocaleContext from '../hooks/LocaleContext';
import { Modal, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

const User = () => {
  const { userSettings, logout, setUserVariables } = useContext(LocaleContext);
  const icon = userSettings.loggedIn ? 'fas' : 'fal';
  const [showModal, setShowModal] = useState(false);

  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const history = useHistory();

  const openSettings = (e) => {
    history.push('/settings');
  };

  return (
    <div className="user-settings">
      <Dropdown className="">
        <Dropdown.Toggle variant="black">
          <FontAwesomeIcon icon={[icon, 'user']} fixedWidth className="my-auto" />
        </Dropdown.Toggle>

        <Dropdown.Menu align="right" style={{}}>
          <div className="user-settings-name">
            {userSettings.firstName} {userSettings.lastName}
          </div>
          <Dropdown.Divider />
          <Dropdown.Item onClick={openSettings} eventKey="settings" className="">
            Settings
          </Dropdown.Item>
          <Dropdown.Item onClick={handleOpen} eventKey="signout" className="user-settings-signout">
            Sign out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Modal centered show={showModal} onHide={handleClose}>
        <Modal.Body>
          <h2 className="modal-title">Sign out</h2>
          <p>Are you sure that you want to sign out?</p>
        </Modal.Body>
        <Modal.Footer>
          <Col>
            <Button variant="outline-success" onClick={handleClose} block>
              Keep me signed in
            </Button>
          </Col>
          <Col>
            <Button variant="outline-danger" onClick={() => logout()} block>
              Sign out
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default User;
