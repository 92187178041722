import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Badge, Button, Col, OverlayTrigger, Popover, Row, Tab, Table } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import AiDetectionIcon from '../AiDetectionIcon';

const Summary = ({ application, candidate, className, _summaries = null }) => {
  const [key, setKey] = useState(0);
  let summaries = [];
  let totalCount = 0;

  if (application && application.summaries && application.summaries.totalCount) {
    summaries = application.summaries.nodes;
    totalCount = application.summaries.nodes.length;
  } else if (_summaries && Array.isArray(_summaries) && _summaries.length) {
    summaries = _summaries;
    totalCount = _summaries.length;
  }

  if (totalCount > 0) {
    console.log('summaries', summaries);
    return (
      <Row className={`summary ${className}`} sm={9}>
        {totalCount > 1 && (
          <Col className="mb-2">
            <Button
              variant="secondary"
              active={key === 0}
              className=""
              onClick={() => {
                setKey(0);
              }}
            >
              Summary 1
            </Button>
            <Button
              variant="secondary"
              active={key === 1}
              className="mx-2"
              onClick={() => {
                setKey(1);
              }}
            >
              Summary 2
            </Button>
          </Col>
        )}
        <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
          <Tab.Content className="w-100 mt-1">
            {summaries.map((summary, index) => {
              return (
                <Tab.Pane eventKey={index} key={`summaryId-${summary.id}`}>
                  <Col className="">
                    {summaries[index].summaryPart.map((sum, index) => {
                      console.log(`Summary Part ${index}`);
                      return (
                        <Row key={index} className="">
                          <PrintSummary key={index} summary={sum} />
                        </Row>
                      );
                    })}
                  </Col>
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </Tab.Container>
      </Row>
    );
  } else if (totalCount === 0) {
    return (
      <Row className="pl-3">
        <Col>
          <Alert className="mt-3 mr-3" variant="info">
            The candidate has not completed the interview.
          </Alert>
        </Col>
      </Row>
    );
  }
  return (
    <Row className={`summary ${className}`} sm={9}>
      <Col>
        <div className="my-2">
          <h2>
            <Skeleton width="50rem" height="2rem" />
          </h2>
          <span className="">
            <Skeleton width="50rem" height="1rem" count={4} />
          </span>
        </div>
      </Col>
    </Row>
  );
};
export default Summary;

const PrintSummary = ({ summary }) => {
  return (
    <Col>
      <Table borderless size="sm" hover className="">
        <thead>
          <tr>
            <th colSpan="2" className={'pl-0'}>
              <h2>
                {summary.header}
                {summary && summary.points ? (
                  <small className="float-right">
                    ({summary.points.toFixed(0)}/{summary.maxPoints}) Threshold: {summary.threshold}
                  </small>
                ) : null}
              </h2>
            </th>
          </tr>
        </thead>
        <tbody>
          {summary.details.map((detail, index) => {
            return <RowDetail detail={detail} key={index} />;
          })}
        </tbody>
      </Table>
    </Col>
  );
};

const RowDetail = ({ detail, index }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <tr key={index} className="border">
      <td>
        <div className="text-center summery-icon d-flex justify-content-between">
          {getIcon(detail, 'list')}
          {detail && detail.aiDetection && detail.aiDetection.isAi ? <AiDetectionIcon /> : <div></div>}
        </div>
      </td>
      <OverlayTrigger
        rootCloseEvent="click"
        rootClose={true}
        placement="top"
        delay={{ show: 400, hide: 50 }}
        trigger="click"
        overlay={
          <Popover className={detail.question.length > 300 ? 'popup large' : 'popup small'}>
            <Popover.Content>
              <div className="popup title">Question</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: detail.question ? detail.question.replace(/^<br>/, '') : detail.label.replace(/^<br>/, ''),
                }}
              ></div>
            </Popover.Content>
          </Popover>
        }
      >
        <td>{detail.label}</td>
      </OverlayTrigger>
      <td className="border" onClick={handleClick}>
        {detail.options && detail.options.length ? (
          <>
            {detail.options.map((option, index) => {
              if (option.evaluation) {
                return (
                  <div key={index}>
                    <span className={``} dangerouslySetInnerHTML={{ __html: option.label }} />
                    <span className="" style={{ fontSize: '1rem' }}>
                      {option.evaluation.name
                        ? getIcon({ icon: option.evaluation.name, bonuspoints: option.evaluation.bonus_points }, 'list')
                        : null}
                    </span>
                  </div>
                );
              } else {
                let classValue = option.answered ? '' : 'outline-';
                classValue += option.isCorrect ? 'success' : 'danger';
                return (
                  <Badge key={`summary-test-option-${index}`} variant={classValue} className="m-2 answerButton">
                    {option.label}
                  </Badge>
                );
              }
            })}
          </>
        ) : (
          <div
            className={`${open ? 'answerOpen' : 'answerClose'} summaryAnswer`}
            dangerouslySetInnerHTML={{ __html: detail.answer ? detail.answer.replace(/^<br>/, '') : '' }}
          ></div>
        )}
      </td>
    </tr>
  );
};

const getCert = (points, iconSize = null) => {
  return (
    <span className="fa-layers fa-fw cert">
      <FontAwesomeIcon
        fixedWidth
        size={iconSize ? iconSize : '1x'}
        className="text-success icon"
        icon={['fas', 'certificate']}
      />
      <span style={{ fontSize: '0.92593rem' }} className="fa-layers-text fa-inverse">
        {points}
      </span>
    </span>
  );
};
const getPointCorrect = (points, iconSize) => {
  return (
    <span className="fa-layers fa-fw cert">
      <FontAwesomeIcon size={iconSize ? iconSize : 'md'} className="text-success" icon={['fas', 'circle']} />
      <span style={{ fontSize: '1rem' }} className="fa-layers-text fa-inverse">
        {points}
      </span>
    </span>
  );
};
const getPointIncorrect = (points, iconSize) => {
  return (
    <span className="fa-layers fa-fw cert">
      <FontAwesomeIcon size={iconSize ? iconSize : 'md'} className="text-danger" fixedWidth icon={['fas', 'circle']} />
      <span style={{ fontSize: '1rem' }} className="fa-layers-text fa-inverse">
        {points}
      </span>
    </span>
  );
};
const getIcon = (summary, large, iconSize) => {
  let icon;

  switch (true) {
    case summary.icon === 'accepted':
      icon = (
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon
            style={{ fontSize: '0.95em' }}
            className="icon text-success"
            fixedWidth
            icon={['fas', 'check']}
          />
        </span>
      );
      break;
    case summary.icon === 'disqualified':
      icon = <FontAwesomeIcon className="icon text-danger" fixedWidth icon={['fas', 'times-circle']} />;
      break;

    case summary.icon === 'point':
      if (typeof summary.average === 'number') {
        icon = (
          <div className={'purple ' + (large ? `score-pill-${large}` : 'score-pill-md')}>
            {Math.round(summary.average)}
          </div>
        );

        icon = (
          <span className="fa-layers fa-fw">
            <FontAwesomeIcon fixedWidth className="purple" icon={['fa', 'circle']} />
            <span style={{ fontSize: '0.92593rem' }} className="fa-layers-text fa-inverse">
              {Math.round(summary.points)}
            </span>
          </span>
        );
      } else if (typeof summary.points === 'number')
        icon = (
          <span className="fa-layers fa-fw cert">
            <span className={'purple ' + (large ? `score-pill-${large}` : 'score-pill-md')}>
              {Math.round(summary.points)}
            </span>
          </span>
        );
      icon = (
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon fixedWidth className="purple" icon={['fa', 'circle']} />
          <span style={{ fontSize: '0.92593rem' }} className="fa-layers-text fa-inverse">
            {Math.round(summary.points)}
          </span>
        </span>
      );
      break;
    case summary.icon === 'warning':
      icon = (
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon
            style={{ fontSize: '0.87em' }}
            className="icon text-warning"
            fixedWidth
            icon={['fas', 'exclamation-triangle']}
          />
        </span>
      );
      break;
    case summary.icon === 'bonus':
      icon = getCert(summary.bonuspoints, iconSize);
      break;
    case summary.points != null:
      if (summary.points !== 0) icon = getPointCorrect(summary.points.toFixed(0));
      else icon = getPointIncorrect(summary.points.toFixed(0));
      break;

    // case summary.icon === 'correct':
    //   icon = getPointIncorrect(summary.points.toFixed(0));
    //   break;
    case summary.icon === 'info':
      icon = <FontAwesomeIcon className="text-grey icon" fixedWidth icon={['fas', 'info-circle']} />;
      break;

    default:
      icon = <FontAwesomeIcon className="text-grey icon" fixedWidth icon={['fas', 'info-circle']} />;
  }
  return icon;
};
