import React, { useContext, useState } from 'react';
import BhWidgetRoutes from './routes';

export const BhTabs = {
  SEND_INTERVIEW: 'Send invite',
  SETTINGS: 'Settings',
  INTERVIEWS: 'Interviews',
  HELP: 'Help',
};

const NavigationContext = React.createContext();

/**
 * Abstract aways navigation logic in the Bullhorn widget. Provides an outlet as a prop to the "children as a function" following the render props patterns.
 */
export const BhNavigation = ({ context, children }) => {
  const [tab, setTab] = useState(BhTabs.INTERVIEWS);

  const outlet = <BhWidgetRoutes active={tab} context={context} />;

  return <NavigationContext.Provider value={{ tab, setTab }}>{children({ outlet })}</NavigationContext.Provider>;
};

/**
 * Provides the interface to control and access state of the navigation in the Bullhorn widget
 */
export const useBhTabbedNavigation = () => {
  const { tab, setTab } = useContext(NavigationContext);

  return {
    active: tab,
    changeTab: setTab,
  };
};
