import { useContext, useMemo } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import LocaleContext from './hooks/LocaleContext';
import ApplicantCardSkeleton from './swiper/ApplicantCardSkeleton';

const stageStatuses = [
  {
    stage: 1,
    name: 'New Applicants',
    color: 'warning',
    show: false,
    status: [
      { stage: 1, name: 'Invited', id: 'invited' },
      { stage: 1, name: 'Opened invitation', id: 'opened_invitation' },
      { stage: 1, name: 'Reminder sent', id: 'reminder_sent' },
      { stage: 1, name: 'Reminder opened', id: 'reminder_opened' },
      { stage: 1, name: 'Started Interview', id: 'interview_started' },
    ],
  },
  {
    stage: 2,
    name: 'Hubert Leads',
    color: 'warning',
    show: false,
    status: [
      { stage: 2, name: 'High scored', id: 'high_score' },
      { stage: 2, name: 'Passed', id: 'passed' },
      { stage: 2, name: 'Unaccepted', id: 'unaccepted' },
    ],
  },
  {
    stage: 3,
    name: 'Accepted Lead',
    color: 'info',
    accepted: true,
    status: [
      { stage: 3, name: 'No next step', id: 'no_next', show: false },
      { stage: 3, name: 'Sent to ATS', id: 'sent_to_ats' },
      { stage: 3, name: 'Contact attempt 1', id: 'contact_attempt_1' },
      { stage: 3, name: 'Contact attempt 2', id: 'contact_attempt_2' },
      { stage: 3, name: 'Contact attempt 3', id: 'contact_attempt_3' },
      { stage: 3, name: 'Pre-screen scheduled', id: 'book_phone_interview', show: false }, // Deprecated id, replaced by pre_screen_scheduled kept during phasing out period
      { stage: 3, name: 'Pre-screen scheduled', id: 'pre_screen_scheduled' },
      { stage: 3, name: 'Phone screened', id: 'phone_interviewed', show: false }, // Deprecated id, deleted. Kept during phasing out period
      { stage: 3, name: 'Interview scheduled', id: 'interviewed', show: false }, // Deprecated id, replaced by interview_scheduled, kept during phasing out period
      { stage: 3, name: 'Interview scheduled', id: 'interview_scheduled' },
      { stage: 3, name: 'Test', id: 'test' },
      { stage: 3, name: 'Check references', id: 'references' },
      { stage: 3, name: 'Offer', id: 'offer' },
    ],
  },
  {
    stage: 4,
    name: 'Hired',
    color: 'success',
    accepted: true,

    status: [{ stage: 4, name: 'Hired', id: 'hired' }],
  },
  {
    stage: 5,
    name: 'Not Hired',
    color: 'danger',
    accepted: false,

    status: [
      //   { stage: 5, name: 'Rejected', id: 'rejected' },
      { stage: 5, name: 'No response', id: 'no_answer', show: false }, // Deprecated id, replaced by no_response, kept during phasing out period
      { stage: 5, name: 'No response', id: 'no_response' },
      { stage: 5, name: 'Failed additional screening', id: 'failed_additional_screening' },
      { stage: 5, name: 'No longer interested', id: 'no_longer_interested' },
      { stage: 5, name: 'Not good enough', id: 'not_good_enough' },
      { stage: 5, name: 'Not qualified', id: 'not_qualified', show: false },
      { stage: 5, name: 'Over qualified', id: 'over_qualified' },
      { stage: 5, name: 'Failed test', id: 'failed_tests' },
      { stage: 5, name: 'Manually rejected', id: 'manually_rejected', show: false },
      { stage: 5, name: 'Hired elsewhere', id: 'hired_elsewhere' },
      { stage: 5, name: 'Offer declined', id: 'offer_declined' },
    ],
  },
  {
    stage: 5,
    name: 'Not Hired',
    color: 'secondary',
    show: false,

    status: [
      //   { stage: 5, name: 'Rejected', id: 'rejected' },
      { stage: 5, name: 'New Applicant', id: 'old_application', show: false },
    ],
  },
];

const getStatusLabel = (id) => {
  for (let s of stageStatuses) {
    for (let statuses of s.status) {
      if (statuses.id === id) return statuses.name;
    }
  }
  return 'New Applicant';
};
const getStatusObject = (id) => {
  for (let s of stageStatuses) {
    for (let statuses of s.status) {
      if (statuses.id === id) return s;
    }
  }
};
const getStatusColor = (id) => {
  for (let s of stageStatuses) {
    for (let statuses of s.status) {
      if (statuses.id === id) return s.color;
    }
  }
  return 'dark';
};

const MockupJobs = ({ title }) => {
  const numSkeletonJobs = useMemo(() => {
    return parseInt(Math.random() * (5 - 2) + 2);
  }, []);

  const numSkeletonApplicants = useMemo(() => {
    const skeletonApplicantsPerJobIndex = [];

    for (let i = 0; i < numSkeletonJobs; i++) {
      skeletonApplicantsPerJobIndex.push(parseInt(Math.random() * (5 - 2) + 2));
    }

    return skeletonApplicantsPerJobIndex;
  }, [numSkeletonJobs]);

  return Array(numSkeletonJobs)
    .fill(1)
    .map((jobtemp, jobi) => {
      return (
        <div key={`${jobi}-skeleton-counter`} className="my-2">
          {title && (
            <h2 className="text-truncate">
              <SkeletonTheme color="#DEDEDE">
                <Skeleton className="" width={'80%'} />
              </SkeletonTheme>
            </h2>
          )}
          {Array(numSkeletonApplicants[jobi])
            .fill(1)
            .map((temp, i) => {
              return <ApplicantCardSkeleton key={i} />;
            })}
        </div>
      );
    });
};

const getScore = ({ application, threshold, size }) => {
  size = size ? 'score-pill-' + size : 'score-pill-lg';
  if (!application) {
    return <Skeleton circle={true} height="2rem" width="2rem" />;
  }
  if (application.stage === 3 || application.stage === 4) {
    if (application.score) return <span className={size + ' green '}>{parseInt(application.score)}</span>;
    else return <span className={size + ' green '}>-</span>;
  } else if (application.stage === 5 && application.status === 'old_application') {
    return <span className={size + ' grey '}>{parseInt(application.score)}</span>;
  } else if (application.stage === 5 && application.score) {
    return <span className={size + ' red '}>{parseInt(application.score)}</span>;
  } else if (application.stage === 5 && isNaN(parseInt(application.score)))
    return <span className={size + ' red '}>-</span>;
  // else if(accepted && parseInt(applicant.score) < 50) return <span className="score-pill purple ">{parseInt(applicant.score)}</span>;
  else {
    if (application.score && !isNaN(parseInt(application.score))) {
      if (parseInt(application.score) >= threshold) {
        return <span className={size + ' purple '}>{parseInt(application.score)}</span>;
      } else if (0 < parseInt(application.score) < threshold) {
        return <span className={size + ' grey '}>{parseInt(application.score)}</span>;
      } else if (isNaN(parseInt(application.score))) return <span className={size + ' grey '}>-</span>;
    } else {
      const firstLetter = application.candidate.firstName ? application.candidate.firstName.slice(0, 1) : '';
      const lastLetter = application.candidate.lastName ? application.candidate.lastName.slice(0, 1) : '';
      return <span className={size + ' grey text-uppercase'}>{firstLetter + lastLetter}</span>;
    }
  }
};

const getStatus = (application) => {
  let text, badge;
  if (application && application.accepted === true) {
    text = getStatusLabel(application.status);
    badge = getStatusColor(application.status);
  } else if (application && application.accepted === false) {
    text = getStatusLabel(application.status);
    badge = getStatusColor(application.status);
  } else if (application && application.status) {
    text = getStatusLabel(application.status);
    badge = getStatusColor(application.status);
  } else if (!application) {
    text = <Skeleton />;
    badge = 'skeleton';
  } else if (application.activityLog && application.activityLog.length > 0) {
    const lastActivity = application.activityLog[application.activityLog.length - 1];
    const actionType = lastActivity.actionType;
    const actionSubType = lastActivity.actionSubType;

    if (lastActivity.message && lastActivity.message !== '') {
      text = lastActivity.message;
      badge = 'secondary';
    } else if (actionType === 'sms') {
      if (actionSubType === 'scheduled') {
        text = 'SMS scheduled';
        badge = 'secondary';
      } else if (actionSubType === 'delivered') {
        text = 'SMS sent';
        badge = 'secondary';
      } else if (actionSubType === 'failed') {
        text = 'SMS failed';
        badge = 'danger';
      }
    }
  } else {
    text = 'New Applicant';
    badge = 'secondary';
  }

  return <Badge variant={badge}>{text}</Badge>;
};

const CandidateName = ({ application, className }) => {
  const { userSettings } = useContext(LocaleContext);

  if (!userSettings.settings.anonymized && application.candidate) {
    // Create full name if first and last name are available, else use either first or last name depending on which one is available
    let fullName = '';

    if (application.candidate.firstName && application.candidate.lastName) {
      fullName = application.candidate.firstName + ' ' + application.candidate.lastName;
    } else if (application.candidate.firstName) {
      fullName = application.candidate.firstName;
    } else if (application.candidate.lastName) {
      fullName = application.candidate.lastName;
    }

    return <span className={className + ' text-capitalize text-truncate '}>{fullName}</span>;
  } else return <span>Applicant</span>;
};

const dateFromObjectId = (objectId) => {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};
const getRandomWidth = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const capitalizeName = (name) => {
  if (name) return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
};

const NamePill = ({ user, size = null, className }) => {
  const colors = ['green', 'purple', 'grey', 'red', 'yellow', 'blue', 'orange', 'pink', 'teal'];

  var color = user.id ? parseInt(user.id.substring(0, 8), 16) % colors.length : 0;
  console.log('className', className);
  return (
    <OverlayTrigger
      delay={500}
      key={`overlay-${user.id}`}
      placment={'bottom'}
      overlay={<Tooltip key={`tooltip-name-${user.id}`}>{`${user.firstName} ${user.lastName}`}</Tooltip>}
    >
      <span
        className={
          size ? ` name-pill-${size} ${colors[color]} ${className}` : `name-pill-${size} ${colors[color]} ${className}`
        }
      >
        {user.firstName.slice(0, 1) + user.lastName.slice(0, 1)}
      </span>
    </OverlayTrigger>
  );
};

export {
  CandidateName,
  MockupJobs,
  NamePill,
  capitalizeName,
  dateFromObjectId,
  getRandomWidth,
  getScore,
  getStatus,
  getStatusColor,
  getStatusLabel,
  getStatusObject,
  stageStatuses,
};
