import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { forwardRef } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Moment from 'react-moment';
import AiDetectionIcon from '../AiDetectionIcon';
import { CandidateName, getScore, getStatus } from '../common';

const ApplicantCard = forwardRef(({ application, select, selectCallback, threshold, columnId, jobId, index }, ref) => {
  var appliedDate,
    latestActivityDate = false;
  if (application) appliedDate = moment(application.createdAt);
  if (application && application.activityLog && application.activityLog.length > 0) {
    latestActivityDate = application.activityLog[application.activityLog.length - 1].createdAt;
  }

  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'few s',
      ss: '%ds',
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      w: '1w',
      ww: '%dwks',
      M: '1mth',
      MM: '%dmths',
      y: '1yr',
      yy: '%dyrs',
    },
  });

  return (
    <div
      className={select ? 'applicant-list-item dark' : 'applicant-list-item shadow-sm' + ' pr-2'}
      id={`applicationId-${application.id}`}
      ref={ref}
      onClick={(e) =>
        selectCallback({
          candidateId: application.candidate.id,
          applicationId: application.id,
          column: columnId,
          jobId: jobId,
          index: index,
        })
      }
    >
      <div className="score">{getScore({ application: application, threshold: threshold, size: 'list' })}</div>
      <div className="pill-height align-self-center d-flex">
        <div>
          <CandidateName application={application} className="name align-top" />
        </div>
        <div className="status-row">
          <span>{getStatus(application)}</span>

          <div className="mr-2">
            {application && application.aiDetection && application.aiDetection.isAi ? (
              <AiDetectionIcon tooltipText="This applicant is likely to have used AI in the interview." />
            ) : null}
          </div>
          <span className="time mr-2">
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip id={`tooltip`}>{`Applied ${moment(appliedDate).format('YYYY-MM-DD HH:mm')}`}</Tooltip>}
            >
              <>
                <FontAwesomeIcon icon={['far', 'clock']} fixedWidth className="" />
                <Moment toNow ago date={appliedDate} interval={10000} unit="minutes" />
              </>
            </OverlayTrigger>
          </span>
          <span className="time">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip id={`tooltip`}>{`Latest activity ${moment(latestActivityDate).format(
                  'YYYY-MM-DD HH:mm',
                )}`}</Tooltip>
              }
            >
              <>
                <FontAwesomeIcon icon={['far', 'spinner']} fixedWidth className="my-auto mr-1" />
                <Moment toNow ago date={latestActivityDate} interval={10000} unit="minutes" />
              </>
            </OverlayTrigger>
          </span>
        </div>
      </div>
    </div>
  );
});

ApplicantCard.displayName = 'ApplicantCard';

export default ApplicantCard;
