import { gql, useMutation } from '@apollo/client';
import loadable from '@loadable/component';
import { useEffect, useMemo, useState } from 'react';
import { Route, BrowserRouter as Router, Switch, useHistory } from 'react-router-dom';

import LoadingScreen from '../components/elements/Loading';
import Forgot from '../components/pages/fullpage/Forgot';
import SetNewPassword from '../components/pages/fullpage/SetNewPassword';
import SignIn from '../components/pages/fullpage/SignIn';
import Signup from '../components/pages/fullpage/Signup';
import DashboardLayout from './DashboardLayout';

import PrivateRoute from '../components/PrivateRoute';
import { fetchAccessToken, setAccessToken } from '../components/accesstoken';
import LocaleContext from '../components/hooks/LocaleContext';

import ZendeskWidget, { ZendeskMessengerApi } from '../components/ZendeskWidget';
import BhWidget from '../components/pages/BullhornWidget';
import OpenApplicationContainer from '../components/pages/OpenApplicationContainer';
import Talentech from '../components/pages/fullpage/Talentech';
import Zendesk from '../components/pages/fullpage/Zendesk';
import LeverAuth from '../components/settings/Integrations/Lever/leverAuth';

const ErrorPage = loadable(() => import('../components/pages/fullpage/404'));

const LOGOUT_MUTATION = gql`
  mutation LogoutMutation($logout: String!) {
    logoutResolverMutation(logout: $logout)
  }
`;

const MainLayout = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const [userSettings, setUserSettings] = useState({
    user: {},
    company: {},
    loggedIn: null,
    settings: {
      anonymized: false,
      showPassed: false,
    },
  });

  useEffect(() => {
    fetchAccessToken()
      .then((token) => {
        if (token) {
          setAccessToken(token);
          setLoggedIn(true);
          setLoading(false);
        } else {
          setAccessToken('');

          setLoggedIn(false);
          setLoading(false);
        }
      })
      .catch((err) => {
        setAccessToken('');
        setLoggedIn(false);
        setLoading(false);
        console.error(err);
      });
  }, []);

  const setUserVariables = ({ user }) => {
    // console.log('Setting', user);
    var newUserSettings = {
      ...userSettings,
      ...user,
    };
    setUserSettings(newUserSettings);
  };
  const toggleAnonymized = () => {
    var newUserSettings = {
      ...userSettings,
      settings: {
        ...userSettings.settings,
        anonymized: !userSettings.settings.anonymized,
      },
    };
    setUserSettings(newUserSettings);
  };
  const togglePassed = () => {
    var newUserSettings = {
      ...userSettings,
      settings: {
        ...userSettings.settings,
        showPassed: !userSettings.settings.showPassed,
      },
    };
    setUserSettings(newUserSettings);
  };

  const setLoggedIn = (newVal) => {
    var newUserSettings = {
      ...userSettings,
      loggedIn: newVal,
    };
    setUserSettings(newUserSettings);
  };

  const [logoutMutation, { client }] = useMutation(LOGOUT_MUTATION, {
    variables: { logout: 'true' },
    onCompleted: (logout) => {
      sessionStorage.clear();
      client.clearStore().then(() => {
        client.resetStore();
        history.push('/login');
      });
      setLoggedIn(false);
      setAccessToken('');
      history.push('/');
    },
  });

  const logout = () => {
    ZendeskMessengerApi.logout();
    logoutMutation();
  };

  const getAccessToken = () => {
    return getAccessToken();
  };

  const userValues = useMemo(
    () => ({
      userSettings,
      toggleAnonymized,
      togglePassed,
      setLoggedIn,
      setUserVariables,
      logout,
      getAccessToken,
    }),
    [userSettings],
  );

  return (
    <LocaleContext.Provider value={userValues}>
      <div className="layout">
        <Router fallback={<span />}>
          <LoadingScreen show={loading} />

          {userSettings.loggedIn !== null && (
            <Switch>
              {/* Alla sidor utan standard mallen */}
              <Route path="/signin" exact component={SignIn} />
              <Route path="/sso/hubert" exact component={() => SignIn({ pid: 'saml.hubert-saml' })} />
              <Route path="/forgot" exact component={Forgot} />
              <Route path="/invitation" exact component={Signup} />
              <Route path="/setpassword" exact component={SetNewPassword} />
              {/* {!userSettings.loggedIn && <Redirect to={'/signin'} />} */}
              <PrivateRoute path="/help/login" exact component={Zendesk} />
              <PrivateRoute path="/auth/talentech" exact component={Talentech} />
              <PrivateRoute path="/lever/login" exact component={LeverAuth} />
              <PrivateRoute path="/BhWidget" component={BhWidget} />
              <PrivateRoute component={DashboardLayout} />
            </Switch>
          )}
        </Router>
        <ZendeskWidget zendeskKey={process.env.REACT_APP_ZENDESK_KEY} />
      </div>
    </LocaleContext.Provider>
  );
};

const Layout = () => {
  const [userSettings, setUserSettings] = useState({
    user: {},
    company: {},
    loggedIn: null,
    settings: {
      anonymized: false,
      showPassed: false,
    },
  });

  const userValues = useMemo(
    () => ({
      userSettings,
    }),
    [userSettings],
  );

  return (
    <LocaleContext.Provider value={userValues}>
      <Router fallback={<span />}>
        <Switch>
          <Route path="/error" exact component={ErrorPage} />
          <Route path={`/application/share/:id`} component={OpenApplicationContainer} />
          <Route path="/" component={MainLayout} />
        </Switch>
      </Router>
    </LocaleContext.Provider>
  );
};

export default Layout;
