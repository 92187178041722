import React, { useState } from 'react';
import { Button, Col, Dropdown, FormControl, Modal } from 'react-bootstrap';
import { useQuery, gql, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import ReadInterview from './ReadInterview';

// eslint-disable-next-line react/display-name
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button className="ml-2" variant="secondary" onClick={(e) => onClick(e)} ref={ref}>
    {children}
  </Button>
));

// eslint-disable-next-line react/display-name
const CustomMenu = React.forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  const [value, setValue] = useState('');

  return (
    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
      <ul className="list-unstyled">
        {React.Children.toArray(children).filter(
          (child) => value || <li>{child.props.children.toLowerCase().startsWith(value)}</li>,
        )}
      </ul>
    </div>
  );
});

const DeleteCandidateModal = ({ show, onClose, deleteApplication }) => {
  const { deleteApplicationCallback, loading } = deleteApplication;
  const deleteConfirmed = async () => {
    await deleteApplicationCallback();
    onClose(false);
  };

  return (
    <Modal centered show={show} onHide={() => onClose(false)}>
      <Modal.Header closeButton={!loading}>
        <h4>Are you sure?</h4>
      </Modal.Header>

      <Modal.Body>
        <p>
          The action is permanent and cannot be undone. The application will only be deleted from Hubert and not any
          external platforms.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Col>
          <Button variant="secondary" disabled={loading} onClick={() => onClose(false)} block>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button variant="danger" disabled={loading} onClick={() => deleteConfirmed()} block>
            {loading ? (
              <FontAwesomeIcon icon={['far', 'spinner']} spin fixedWidth size="lg" className="" />
            ) : (
              <span>Delete Application</span>
            )}
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

const CandidateDropdown = ({ children, application, deleteApplication }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInterview, setShowInterview] = useState(false);

  const history = useHistory();

  const viewJob = () => {
    history.push('/job/' + application.job.id);
  };

  const viewTeamtailor = () => {
    const integration = application.integration.teamtailor;
    const companyId = application.job.integration.teamtailor.companyId;
    window.open(
      `https://app.teamtailor.com/companies/${companyId}/jobs/${integration.jobId}/stages/candidate/${integration.candidateId}`,
    );
  };

  const viewCandidateProfile = () => {
    const candidateId = application.candidate.id;
    history.push(`/candidates/${candidateId}`);
  };

  const viewOpenApplication = () => {
    const applicationId = application.id;
    if (process.env.REACT_APP_ENVIRONMENT !== 'DEV') {
      window.open(`https://app.hubert.ai/application/share/${applicationId}`);
    } else {
      window.open(`https://provrum.hubert.ai/application/share/${applicationId}`);
      // window.open(`http://localhost:3000/application/share/${applicationId}`);
    }
  };

  return (
    <>
      <ReadInterview
        show={showInterview}
        handleClose={() => setShowInterview(false)}
        id={application.id}
        application={application}
      />

      {deleteApplication && (
        <DeleteCandidateModal
          deleteApplication={deleteApplication}
          show={showDeleteModal}
          onClose={setShowDeleteModal}
        />
      )}

      <Dropdown className="dropdown-inline">
        <Dropdown.Toggle as={CustomToggle}>
          <FontAwesomeIcon icon={['far', 'ellipsis-h']} fixedWidth className="my-auto" />
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu}>
          <Dropdown.Item
            onClick={() => {
              setShowInterview(true);
            }}
          >
            Read interview
          </Dropdown.Item>
          {application.integration && application.integration.teamtailor && (
            <Dropdown.Item onClick={viewTeamtailor}>Application in Teamtailor</Dropdown.Item>
          )}
          <Dropdown.Item onClick={viewJob}>View job</Dropdown.Item>
          <Dropdown.Item onClick={viewCandidateProfile}>Candidate profile</Dropdown.Item>
          {deleteApplication && (
            <Dropdown.Item
              className="dropdown-warning"
              onClick={() => {
                setShowDeleteModal(true);
              }}
            >
              Delete application
            </Dropdown.Item>
          )}
          <Dropdown.Item onClick={viewOpenApplication}>Open Application Summary</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default CandidateDropdown;
