import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'sonner';
import './CopyLink.scss';

const CopyLink = ({ url, className }) => {
  const handleCopyClick = () => {
    navigator.clipboard.writeText(url).then(() => {
      // Provide feedback to the user if needed
      toast.success('Link copied to clipboard', { duration: 1500, dismissible: true });
    });
  };

  return (
    <div className={`copy-link ` + className}>
      <div className="copy-link__input-wrapper">
        <input type="text" value={url} readOnly className="copy-link__input" />
        <div className="copy-link__blur"></div>
      </div>
      <button className="copy-link__button" onClick={handleCopyClick}>
        <FontAwesomeIcon icon={['far', 'copy']} className="copy-link-icon" />
        Copy Link
      </button>
    </div>
  );
};

export default CopyLink;
