import { gql, useQuery } from '@apollo/client';

import { Container, Row } from 'react-bootstrap';
import Header from '../swiper/Header';
import Summary from '../swiper/Summary';

const GET_APPLICATION = gql`
  query OpenApplication($applicationId: String!) {
    OpenApplication(applicationId: $applicationId) {
      id
      score
      status
      score
      stage
      createdAt
      accepted
      activityLog {
        createdAt
        status
        stage
        actionType
        message
      }
      candidate {
        id
        firstName
        lastName
        email
        phoneNumber
      }
      job {
        id
        company
        title
        threshold
        location {
          name
        }
        position
      }
      _summaries {
        id
        threshold
        aiDetection {
          isAi
          score
          tag
        }
        summaryPart: summary {
          header
          icon
          bonuspoints
          average
          points
          maxPoints
          threshold
          details {
            label
            question
            icon
            answer
            points
            bonuspoints
            isCorrect
            qualified
            options {
              label
              points
              isCorrect
              answered
              evaluation {
                name
                value
                bonus_points
              }
            }
            aiDetection {
              isAi
              score
              tag
            }
          }
        }
      }
    }
  }
`;

const OpenApplication = ({ applicationId, whileLoading }) => {
  const { loading, error, data } = useQuery(GET_APPLICATION, { variables: { applicationId: applicationId } });
  if (loading) return whileLoading ?? 'Loading...';
  if (error) return `Error! ${error.message}`;

  const job = data.OpenApplication.job;
  const candidate = data.OpenApplication.candidate;

  return (
    <>
      <Container>
        <Row className={'header-row d-flex justify-content-between '}>
          <span className={'my-auto '}>
            <span className=" float-left">
              <h3 className="">
                {job.company} - {job.title}
              </h3>
              <h4>
                {job && job.position && <span>{job.position} - </span>}
                {job && job.location && job.location.name && <span>{job.location.name}</span>}
              </h4>
            </span>
          </span>
        </Row>
        <Header
          application={data.OpenApplication}
          threshold={100}
          selectedCallback={() => {
            console.log('Do nothing.');
          }}
          deleteApplication={() => {
            console.log('Do nothing.');
          }}
          jobLink
          editableStatus={false}
          readInterview={false}
          reInvite={false}
          candidateDropdown={false}
        />
        <Summary
          application={data.OpenApplication}
          candidate={candidate}
          _summaries={data.OpenApplication._summaries}
        />
      </Container>
    </>
  );
};

export default OpenApplication;
