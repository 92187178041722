import React from 'react';
import { BhTabs } from './BhNavigation';
import BhWidgetSettings from './BhWidgetSettings';
import InterviewsListContainer from './InterviewsListContainer';
import SendInterviewContainer from './SendInterviewContainer';
import Help from './Help';

const BhWidgetRoutes = ({ active, context }) => {
  const bullhornJobOrderId = +context.EntityID;

  switch (active) {
    case BhTabs.SETTINGS:
      return <BhWidgetSettings job={bullhornJobOrderId} />;
    case BhTabs.SEND_INTERVIEW:
      return <SendInterviewContainer job={bullhornJobOrderId} />;
    case BhTabs.HELP:
      return <Help context={context} />;
    case BhTabs.INTERVIEWS:
    default:
      return <InterviewsListContainer job={bullhornJobOrderId} />;
  }
};

export default BhWidgetRoutes;
