import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SimpleLoader = () => (
  <div className="loader">
    <FontAwesomeIcon icon={['far', 'spinner']} spin size="lg" className="color dark-grey" />
  </div>
);

export default SimpleLoader;
