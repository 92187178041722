import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

import { ApolloLink, Observable } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import jwtDecode from 'jwt-decode';

import Layout from './layout/Layout';

import { getAccessToken, setAccessToken } from './components/accesstoken';
import './style/sass/custom.scss';

// import ErrorCatcher from './components/elements/ErrorCatcher';

/**
 * Font awesome
 */
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { toast, Toaster } from 'sonner';
library.add(fas, far, fal);

const url = process.env.REACT_APP_GRAPHQL_URL || 'https://backapp.hubert.ai';

if (process.env.REACT_APP_ENVIRONMENT !== 'DEV') {
  console.log = function () {};
  // removes console.log
}

const uri_refresh = url + '/refresh_token';
const uri = url + '/graphql';

const cache = new InMemoryCache({
  typePolicies: {
    User: {
      fields: {
        pinnedJobs: {
          merge(existing = [], incoming = []) {
            return incoming;
          },
        },
      },
    },
    Job: {
      fields: {
        location: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
      },
    },
  },
});

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((operation) => {
          const accessToken = getAccessToken();
          if (accessToken) {
            operation.setContext({
              headers: {
                authorization: `bearer ${accessToken}`,
              },
            });
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);

const ignoredErrorPaths = ['ForgotPasswordMagicLink', 'loginResolverMutation', 'changePassword'];

const client = new ApolloClient({
  link: ApolloLink.from([
    new RetryLink({
      attempts: {
        max: 5,
        retryIf: async (error) => {
          if (error.statusCode && (error.statusCode === 403 || error.statusCode === 407)) return false;
          return true;
        },
      },
    }),
    new TokenRefreshLink({
      accessTokenField: 'accessToken',
      isTokenValidOrUndefined: () => {
        const token = getAccessToken();
        if (!token) {
          return false;
        }
        try {
          const { exp } = jwtDecode(token);
          if (Date.now() >= exp * 1000) {
            return false;
          } else {
            return true;
          }
        } catch {
          return false;
        }
      },
      fetchAccessToken: () => {
        console.info('fetching token');
        return fetch(uri_refresh, {
          method: 'POST',
          credentials: 'include',
        });
      },
      handleFetch: (accessToken) => {
        console.info('Set accesstoken ');
        setAccessToken(accessToken);
      },
      handleError: (err) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        // useHistory().push('/signin');
        console.warn('Your refresh token is invalid. Try to relogin');
        console.warn(err.message);
        setAccessToken('');
        // eslint-disable-next-line no-restricted-globals
        //location.reload();
      },
    }),

    onError((errors) => {
      const { graphQLErrors, networkError } = errors;
      if (graphQLErrors) {
        graphQLErrors.forEach(({ code, message, locations, path }) => {
          if (message === 'Job stat not available') {
            graphQLErrors.errors = 'JOB_STAT_ERROR';
            return;
          }
          if (code === 'FORBIDDEN') {
            toast.error(message);
            return;
          }
          if (code === 'UNAUTHORIZED' || message === 'Not authenticated') {
            setAccessToken('');
            //eslint-disable-next-line no-restricted-globals
            location.reload();
          } else if (path && ignoredErrorPaths.includes(path[0])) {
            graphQLErrors.errors = null;
            return;
          } else toast.error("Something went wrong. We're looking into it.", { description: message });
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        });
      }
      if (networkError) {
        console.warn(`[Network error]: ${networkError}`, { networkError, operation: errors.operation });
      }
    }),
    requestLink,
    new HttpLink({
      uri: uri,
      credentials: 'include',
    }),
  ]),
  cache,
});

export default function App() {
  return (
    <>
      <ApolloProvider client={client}>
        <Layout />
      </ApolloProvider>
      <Toaster richColors position="bottom-center" closeButton />
    </>
  );
}
