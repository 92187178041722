/* eslint-disable react/display-name */
import React from 'react';
import { Badge, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getStatusLabel, stageStatuses, getStatusColor } from '../common';

const BadgeDropdown = ({ initStatus, statuscallback, className = '', editableStatus = true }) => {
  if (!initStatus) initStatus = 'old_application';
  var type = { variant: getStatusColor(initStatus), status: getStatusLabel(initStatus) };

  const setNew = (e) => {
    statuscallback({ type: 'status-execute', status: e });
  };

  return (
    <div className={'BadgeDropdown ' + className}>
      <Dropdown className="clickeable" onSelect={setNew}>
        <Dropdown.Toggle as={BadgeToggle} variant={type.variant}>
          {type.status}
        </Dropdown.Toggle>

        {editableStatus ? (
          <Dropdown.Menu>
            {stageStatuses.map((stage, stageIndex) => {
              if (stage.show === false) return null;
              else
                return stage.status.map((status, statusIndex) => {
                  var header = (
                    <Dropdown.Header key={'header' + statusIndex + status.id} className={stage.color}>
                      Stage: {stage.name}
                    </Dropdown.Header>
                  );
                  var item = (
                    <Dropdown.Item
                      key={'badgedropdown-' + statusIndex + status.id}
                      eventKey={status.id}
                      className={stage.color}
                      active={initStatus === status.id}
                    >
                      {status.name}
                    </Dropdown.Item>
                  );
                  var headerItem = (
                    <>
                      <Dropdown.Header key={`header-dropdown-${statusIndex}${status.id}`} className={stage.color}>
                        Stage: {stage.name}
                      </Dropdown.Header>
                      <Dropdown.Item
                        key={statusIndex}
                        eventKey={status.id}
                        className={stage.color}
                        active={initStatus === status.id}
                      >
                        {status.name}
                      </Dropdown.Item>
                    </>
                  );
                  if (statusIndex === 0 && status.show === false) return header;
                  else if (status.show === false) return null;
                  else if (statusIndex === 0) return headerItem;
                  else return item;
                });
            })}
          </Dropdown.Menu>
        ) : (
          <></>
        )}
      </Dropdown>
    </div>
  );
};

export default BadgeDropdown;

const BadgeToggle = React.forwardRef(({ children, onClick, variant, statuscallback, block }, ref) => (
  <Badge
    variant={variant}
    ref={ref}
    className="d-block clickable"
    statuscallback={statuscallback}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children} <FontAwesomeIcon icon={['far', 'angle-down']} fixedWidth className="my-auto" />
  </Badge>
));
