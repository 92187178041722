import { gql } from '@apollo/client';

export const LEVER_AUTHORIZE_URL = gql`
  query LEVER_CONFIG($clientId: String) {
    clientIntegrations(clientId: $clientId) {
      Lever {
        authorizeURL {
          URL
        }
      }
    }
  }
`;

export const GET_LEVER_CONFIGS = gql`
  query LEVER_CONFIG($clientId: String) {
    clientIntegrations(clientId: $clientId) {
      Lever {
        config {
          autoMove {
            moveLeadsTo
            moveNotQualifiedTo
            movePassedTo
          }
        }
        stages {
          id
          name
        }
      }
    }
  }
`;

export const INTEGRATION_INFO = gql`
  query Viewer {
    viewer {
      client {
        integrations {
          id
          name
          clientId
          archived
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const UPDATE_LEVER_CONFIGS = gql`
  mutation UpdateLeverConfig(
    $moveLeadsTo: String
    $moveNotQualifiedTo: String
    $movePassedTo: String
    $clientId: String!
  ) {
    updateLeverConfig(
      input: {
        moveLeadsTo: $moveLeadsTo
        moveNotQualifiedTo: $moveNotQualifiedTo
        movePassedTo: $movePassedTo
        clientId: $clientId
      }
    ) {
      __typename
      ... on LeverConfig {
        autoMove {
          moveLeadsTo
          moveNotQualifiedTo
          movePassedTo
        }
      }
      ... on IntegrationAPIError {
        message
      }
    }
  }
`;

export const CONNECT_TO_LEVER = gql`
  mutation LEVER_INTEGRATION_CONNECT($clientId: String!, $code: String!) {
    integrateLever(input: { clientId: $clientId, code: $code }) {
      __typename
      ... on LeverIntegrationSuccess {
        success
      }
      ... on IntegrationAPIError {
        message
      }
    }
  }
`;

export const AUTHORIZE_SMARTRECRUITERS = gql`
  mutation AuthorizeSmartRecruiters($companyId: String!, $clientId: String!) {
    integrateSmartRecruiters(input: { companyId: $companyId, clientId: $clientId }) {
      __typename
      ... on SmartRecruitersIntegrationSuccess {
        success
      }
      ... on IntegrationAPIError {
        message
      }
    }
  }
`;

export const DISCONNECT_LEVER = gql`
  mutation LEVER_DISCONNECT($clientId: String!) {
    disconnectLever(input: { clientId: $clientId }) {
      __typename
      ... on LeverDisconnectSuccess {
        success
      }
      ... on IntegrationAPIError {
        message
      }
    }
  }
`;
