import React from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useQuery, gql } from '@apollo/client';

import { Row, Container, Col, Button, Tooltip, OverlayTrigger, Badge, Alert } from 'react-bootstrap';

import ListJobs from '../jobs/ListJobs';
import { MockupJobs, getScore } from '../common';
import Title from '../elements/Title';
import { Error } from './Error';
import ApplicantCard from '../swiper/ApplicantCard';

const JOBS_QUERY = gql`
  {
    getLeads: allJobs(filter: { active: true }, sort: { field: _id, order: ASC }, limit: 3) {
      totalCount
      nodes {
        title
        id
        userId
        company
        active
        createdAt
        threshold
        location {
          id
          name
          address
        }
        applications(
          filter: { score: { gte: 0 }, stage: { equals: 2 }, accepted: { equals: null } }
          sort: { field: score, order: DESC }
          limit: 3
        ) {
          totalCount
          nodes {
            id
            score
            stage
            status
            createdAt
            activityLog {
              createdAt
              status
              stage
              actionType
              message
            }
            candidate {
              firstName
              lastName
              email
              id
            }
          }
        }
      }
    }
    getNext: allJobs(filter: { active: true }, sort: { field: _id, order: ASC }, limit: 3) {
      totalCount
      nodes {
        title
        id
        userId
        company
        active
        createdAt
        threshold
        location {
          id
          name
          address
        }
        applications(
          filter: { stage: { equals: 3 }, score: { gt: 0 }, accepted: { equals: true } }
          sort: { field: score, order: DESC }
          limit: 3
        ) {
          totalCount
          nodes {
            id
            score
            stage
            accepted
            status
            createdAt
            activityLog {
              createdAt
              status
              stage
              actionType
              message
            }
            candidate {
              firstName
              lastName
              email
              id
            }
          }
        }
      }
    }
    activeJobs: allJobs(filter: { active: true }, limit: 15, sort: { field: _id, order: DESC }) {
      totalCount
      nodes {
        title
        id
        userId
        company
        active
        createdAt
        threshold
        location {
          id
          name
          address
        }
        new: applications(filter: { stage: { equals: null } }) {
          totalCount
        }
        leads: applications(filter: { stage: { equals: 2 } }) {
          totalCount
        }
        accepted: applications(filter: { accepted: { equals: true } }) {
          totalCount
        }
        hired: applications(filter: { stage: { equals: 4 } }) {
          totalCount
        }
        notHired: applications(filter: { stage: { equals: 5 } }) {
          totalCount
        }
        manualRejected: applications(filter: { accepted: { equals: false } }) {
          totalCount
        }
        total: applications {
          totalCount
        }
      }
    }
  }
`;
const Home = () => {
  const history = useHistory();
  const location = useLocation();
  const { data, error, loading, networkStatus } = useQuery(JOBS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const SelectCallback = (e) => {
    if (e.to === 'leads' || e.to === 'job') history.push(`/job/${e.jobId}/${e.applicationId}`);
    else history.push(`/candidates/${e.candidateId}/${e.applicationId}`);
  };
  return (
    <>
      <Title title={'Home'} />
      <Container fluid>
        {Error(error, location, () => (
          <Row>
            <Alert>
              Something went wrong, please reload the page and contact an administrator if the error persists.
            </Alert>
          </Row>
        ))}
        <Row className="border-grey-top pt-3">
          <Col xl={3} lg={6} className="kanban">
            <h1>New Leads</h1>
            <Row>
              <Col className={'kanban-column'}>
                {loading && <MockupJobs title="true" />}
                {!loading && data && (
                  <ListApplicationInJobs jobs={data.getLeads} selectCallback={SelectCallback} to={'leads'} />
                )}
              </Col>
            </Row>
          </Col>
          <Col xl={3} lg={6} className="kanban mx-auto">
            <h1>Next Step</h1>
            <Row>
              <Col className="kanban-column">
                {!data && <MockupJobs title="true" />}
                {data && <ListApplicationInJobs jobs={data.getNext} selectCallback={SelectCallback} to={'job'} />}
              </Col>
            </Row>
          </Col>
          <Col xl={6} lg={12}>
            <h1>Latest Jobs</h1>
            <Row>
              <Col className="jobs">
                {data?.activeJobs?.nodes?.length === undefined}
                <ListJobs
                  loading={loading && data?.activeJobs?.nodes?.length === undefined}
                  jobs={data ? data.activeJobs.nodes : null}
                  size="small"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Home;

const ListApplicationInJobs = ({ jobs, selectCallback, to }) => {
  var list = jobs.nodes.map((job, i) => {
    if (job.applications.nodes.length > 0) {
      return (
        <div key={`job-list-${job.id}`} className="">
          <NavLink to={to === 'job' ? 'job/' + job.id : 'job/' + job.id} className="text-decoration-none">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip id={`tooltip`}>
                  {job.title} {job && job.location && job.location.name && <span> - {job.location.name}</span>}{' '}
                </Tooltip>
              }
            >
              <h2 className="text-truncate text-black  ">
                {job.title}
                {job && job.location && job.location.name && (
                  <Badge variant="info" className="mx-2">
                    {job.location.name}
                  </Badge>
                )}
              </h2>
            </OverlayTrigger>
          </NavLink>
          {job.applications.nodes.map((application, i) => {
            return (
              <ApplicantCard
                key={`list-application-in-jobs-${i}-${application.id}`}
                jobId={job.id}
                application={application}
                selectCallback={(e) => selectCallback({ ...e, to: to })}
                threshold={job.threshold}
              />
            );
          })}
          {job.applications.totalCount > 3 && (
            <div className="showing-top-text">
              Showing top {job.applications.nodes.length + ' of ' + job.applications.totalCount}
            </div>
          )}
        </div>
      );
    } else return null;
  });

  if (list.length === 0)
    return (
      <div className="">
        <h2 className="text-truncate text-black ">No jobs yet</h2>
      </div>
    );
  return list;
};
