import { faCheckSquare } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import copy from 'copy-to-clipboard';

function Help({ context }) {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const copyContextToClipboard = () => {
    copy(JSON.stringify(context));
    setCopiedToClipboard(true);
  };

  return (
    <div className="help-page">
      <h2>Diagnostic information</h2>
      <p>
        If you are facing issues with the widget, please use the button below to copy diagnostic information that you
        can share with Hubert support.
      </p>
      <Button variant="secondary" onClick={copyContextToClipboard}>
        {copiedToClipboard ? (
          <>
            Copied{' '}
            <span className="check-mark-icon">
              <FontAwesomeIcon icon={faCheckSquare} />
            </span>
          </>
        ) : (
          'Click to copy'
        )}
      </Button>
    </div>
  );
}

export default Help;
