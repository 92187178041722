import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import useSearchParams from '../../hooks/useSearchParams';
import { BhNavigation } from './BhNavigation';
import BhSidebar from './BhSidebar';

const BhWidget = () => {
  const searchParams = useSearchParams();
  const [BhContext] = useState(Object.fromEntries(Array.from(searchParams.entries())));

  return (
    <Container className="bhwidget main-container">
      <Row className="content" style={{ margin: '15px 0px' }}>
        <BhNavigation context={BhContext}>
          {({ outlet }) => (
            <>
              <Col sm="auto">
                <BhSidebar />
              </Col>
              <Col sm>{outlet}</Col>
            </>
          )}
        </BhNavigation>
      </Row>
    </Container>
  );
};

export default BhWidget;
