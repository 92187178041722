import { gql } from '@apollo/client';

export const BULLHORN_JOB_SETTINGS_QUERY = gql`
  query BullhornJobSettings($bullhornJobOrderId: Int!) {
    bullhornJobSettings(bullhornJobOrderId: $bullhornJobOrderId) {
      autoInvite
      templateId
      templateName
      threshold
    }
  }
`;
