import { ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export class BetterRollbarErrorBoundary extends RollbarErrorBoundary {
  constructor(props) {
    super(props);
    Object.assign(this.state, { hasError: false });
  }

  static getDerivedStateFromError(error) {
    return Object.assign({ hasError: true }, RollbarErrorBoundary.getDerivedStateFromError(error));
  }

  componentDidCatch(error, info) {
    const { message, name, stack, ...rest } = error;
    error.rollbarContext = {
      name,
      ...rest,
    };
    if (this.props.onError) this.props.onError(error);
    if (super.componentDidCatch) super.componentDidCatch(error, info);
  }

  componentDidUpdate(prevProps, _) {
    if (!this.props.hasError && prevProps.hasError) {
      this.setState({ hasError: false });
    }
  }
}

export function RouterErrorBoundary({ children, fallbackUI }) {
  const [hasError, setHasError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (hasError) setHasError(false);
  }, [location.key]);

  const onError = (error) => {
    console.error(error);
    setHasError(true);
  };
  return (
    <BetterRollbarErrorBoundary hasError={hasError} onError={onError} fallbackUI={fallbackUI}>
      {children}
    </BetterRollbarErrorBoundary>
  );
}
