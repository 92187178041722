import React, { useContext, useRef } from 'react';

import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import User from './User';
import Searchbar from './Searchbar';
import LocaleContext from '../hooks/LocaleContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PinnedJobs from './PinnedJobs';

const HelpCenter = () => {
  return (
    <OverlayTrigger delay={300} placement={'bottom'} overlay={<Tooltip>Help Center</Tooltip>}>
      <a
        href="https://hubert9080.zendesk.com/"
        target="_blank"
        style={{ display: 'flex', color: 'white' }}
        rel="noreferrer"
      >
        <FontAwesomeIcon fixedWidth icon={['fas', 'question-circle']} className="my-auto" />
      </a>
    </OverlayTrigger>
  );
};

const NavbarTop = () => {
  const { userSettings } = useContext(LocaleContext);

  return (
    <div className="navbar-top align-items-center m-0 px-2">
      <Row className="vertical-center">
        <Col>
          <NavLink activeClassName="active" exact to="/">
            <img src="/logo/LogoNaked@0.5x.png" className="navbar-logo py-3 mr-2 ml-1" alt="Hubert Logo" />
          </NavLink>
          {/* </Col>
        <Col> */}
          <NavLink activeClassName="active" exact to="/jobs" className="navbar-item ">
            Jobs
          </NavLink>
          {/* </Col>
        <Col lg={2}> */}
          {/* <NavLink activeClassName="active" exact to="/candidates" className="navbar-item">
            Candidates
          </NavLink> */}
          {/* </Col>
        <Col lg={2}> */}
        </Col>
        <Col className="flex-grow d-lg-flex mx-0 px-0 justify-content-end" sm={2} lg={6}>
          <Searchbar className=" d-none d-lg-flex" history={useHistory()} />
          <PinnedJobs />
          <HelpCenter />
          <User />
        </Col>
        {/* <Col className="col-1">
        </Col> */}
      </Row>
    </div>
  );
};

export default NavbarTop;
