import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Sidebar, Menu, MenuItem, sidebarClasses } from 'react-pro-sidebar';
import { BhTabs, useBhTabbedNavigation } from './BhNavigation';
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/pro-regular-svg-icons';
import { faComment } from '@fortawesome/pro-regular-svg-icons';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';

function BhSidebar() {
  const [collapsed, setCollapsed] = useState(false);
  const { active, changeTab } = useBhTabbedNavigation();

  const toggleCollapse = () => setCollapsed(!collapsed);

  return (
    <Sidebar
      collapsed={collapsed}
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          background: 'rgb(242, 242, 247, 1)',
          backdropFilter: 'blur(6px)',
          borderRadius: '12px',
        },
      }}
      style={{
        border: 'none',
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '12px 12px 12px 4px',
        }}
      >
        <div
          style={{
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <img src="/logo/hubert_logo_lockup_dark.png" className="navbar-logo" alt="Hubert Logo" />
        </div>
        {collapsed ? (
          <FontAwesomeIcon icon={faArrowCircleRight} size="lg" onClick={toggleCollapse} />
        ) : (
          <FontAwesomeIcon icon={faArrowCircleLeft} size="lg" onClick={toggleCollapse} />
        )}
      </div>
      <Menu
        menuItemStyles={{
          button: ({ active }) => {
            if (active) {
              return {
                background: '#007aff',
                color: 'rgb(255, 255, 255)',
                ':hover': {
                  background: '#007aff',
                  color: 'rgb(255, 255, 255)',
                },
              };
            }

            return {
              ':hover': {
                background: '#007aff40',
                color: 'rgb(255, 255, 255)',
                transition: '0.3s ease',
              },
            };
          },
        }}
      >
        <MenuItem
          active={active === BhTabs.INTERVIEWS || active === BhTabs.SEND_INTERVIEW}
          className="menu-item"
          icon={<FontAwesomeIcon size="lg" icon={faComment} />}
          onClick={() => changeTab(BhTabs.INTERVIEWS)}
        >
          Interviews
        </MenuItem>
        <MenuItem
          active={active === BhTabs.SETTINGS}
          icon={<FontAwesomeIcon icon={faCog} size="lg" />}
          onClick={() => changeTab(BhTabs.SETTINGS)}
        >
          Settings
        </MenuItem>
        <MenuItem
          active={active === BhTabs.HELP}
          icon={<FontAwesomeIcon icon={faQuestionCircle} size="lg" />}
          onClick={() => changeTab(BhTabs.HELP)}
        >
          Help
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}

export default BhSidebar;
