import { Alert, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'sonner';

import { SignInUrl } from './Jobs';

const reasons = new Set(['access-token-missing', 'access-token-expired']);

const accessTokenMissingOrExpired = (error) => {
  const { networkError } = error;
  if (networkError && networkError.statusCode === 403) return true;

  const { graphQLErrors } = error;
  if (graphQLErrors && graphQLErrors.length > 0)
    for (const item of graphQLErrors) if (item.code === 'UNAUTHORIZED' && reasons.has(item.reason)) return true;

  return false;
};

export const Error = (error, location, defaultError) => {
  const history = useHistory();
  if (!error) return <Row></Row>;
  if (accessTokenMissingOrExpired(error)) {
    toast.warning('Your session has expired.', {
      important: true,
      action: { label: 'Sign In', onClick: history.replace(SignInUrl(location)) },
    });
    return <Row></Row>;
  }
  if (defaultError) return defaultError();

  return (
    <Row>
      <Alert>{error && <pre>{JSON.stringify(error, null, 2)}</pre>}</Alert>
    </Row>
  );
};
