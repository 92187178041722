import { useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';

import { gql, useQuery } from '@apollo/client';

import { useLazyQuery } from '@apollo/client';
import loadable from '@loadable/component';
import jwt_decode from 'jwt-decode';
import { RouterErrorBoundary } from '../BetterRollbarErrorBoundary';
import { ZendeskMessengerApi } from '../components/ZendeskWidget';
import LocaleContext from '../components/hooks/LocaleContext';
import NavbarTop from '../components/navbar/NavbarTop';
import Home from '../components/pages/Home';

const DashboardRoutes = loadable(() => import('../DashboardRoutes'));
const USER_QUERY = gql`
  query {
    viewer {
      id
      firstName
      lastName
      email
      phone
      client {
        viewerIsAdmin
        id
        orgName
        settings {
          anonymized
          showPassed
        }
      }
    }
  }
`;

const ZENDESK_AUTH = gql`
  query ZendeskMessagingAuthentication {
    ZendeskMessagingAuthentication {
      token
    }
  }
`;

const ErrorMessage = ({ error }) => {
  console.info('An error occurred.', { error });
  return <>{error && <h2>Something went wrong. We're very sorry. Please try again.</h2>}</>;
};

const DebugView = () => {
  console.debug('hello debug world.');
  throw new Error('hello error world.');
};

const DashboardLayout = ({ location }) => {
  const { setUserVariables } = useContext(LocaleContext);
  const { data } = useQuery(USER_QUERY);
  const [authZendesk, { data: zendeskData }] = useLazyQuery(ZENDESK_AUTH);

  useEffect(() => {
    DashboardRoutes.preload();
    let zendeskMessagingTokenTimer;

    const renewZendeskToken = async () => {
      const zendeskResult = await authZendesk();
      const token = zendeskResult?.data?.ZendeskMessagingAuthentication?.token;
      if (!token) return undefined;
      const decoded_token = jwt_decode(token);

      await ZendeskMessengerApi.login(token);
      const now = new Date();
      const tokenExpires = new Date(decoded_token.exp);
      // Time to renew 5 minutes before token expires
      const timeToRenew = tokenExpires.getTime() - now.getTime() - 300000;

      return timeToRenew;
    };

    if (process.env.REACT_APP_ENVIRONMENT !== 'DEV') {
      renewZendeskToken().then((timeToRenew) => {
        if (timeToRenew) zendeskMessagingTokenTimer = setInterval(renewZendeskToken, timeToRenew);
      });
    }

    return () => {
      clearInterval(zendeskMessagingTokenTimer);
    };
  }, [authZendesk]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (data)
      setUserVariables({
        user: {
          id: data.viewer.id,
          firstName: data.viewer.firstName,
          lastName: data.viewer.lastName,
          email: data.viewer.email,
          phone: data.viewer.phone,
          contact: data.viewer.contact,
          company: {
            admin: data.viewer.client
              ? data.viewer.client.viewerIsAdmin
                ? data.viewer.client.viewerIsAdmin
                : false
              : false,
            name: data.viewer.client ? data.viewer.client.orgName : null,
            id: data.viewer.client ? data.viewer.client.id : null,
          },
          settings: {
            anonymized: data.viewer.client
              ? data.viewer.client.settings.anonymized
                ? data.viewer.client.settings.anonymized
                : false
              : false,
            showPassed: data.viewer.client
              ? data.viewer.client.settings.showPassed !== null
                ? data.viewer.client.settings.showPassed
                : true
              : true,
          },
        },
      });
  }, [data]);

  return (
    <>
      <NavbarTop />
      <Container className="main-container">
        <Row>
          <Col>
            <RouterErrorBoundary fallbackUI={ErrorMessage}>
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/debug" exact component={DebugView} />
                <DashboardRoutes />
              </Switch>
            </RouterErrorBoundary>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DashboardLayout;
