import React, { useState, useContext, useEffect, useRef } from 'react';
import { NavLink, useHistory, useLocation, useSearchParams } from 'react-router-dom';
import { useMutation, gql, useQuery, useLazyQuery } from '@apollo/client';
import LocaleContext from '../../hooks/LocaleContext';

const VIWER = gql`
  query Viewer {
    viewer {
      isAdmin
    }
  }
`;

const TALENTECH_AUTH = gql`
  query TALENTECH_AUTH($clientId: String, $scope: String) {
    TalentechAuthorization(clientId: $clientId, scope: $scope) {
      authorizationCode
    }
  }
`;

const Talentech = (props) => {
  const { data, error, refetch, loading } = useQuery(VIWER);
  const [getAuthCode, { data: AuthCodeData, loading: authLoading }] = useLazyQuery(TALENTECH_AUTH);
  const returnTo = new URLSearchParams(props.location.search).get('redirect_uri');
  const state = new URLSearchParams(props.location.search).get('state');
  const { userSettings } = useContext(LocaleContext);
  console.log(props.location);

  console.log(data);

  useEffect(() => {
    const getAuth = () => {
      getAuthCode().then((res) => {
        if (returnTo && state && res.data?.TalentechAuthorization !== null) {
          const redirectUrl = `${returnTo}?state=${state}&code=${res.data.TalentechAuthorization.authorizationCode}`;
          window.location.href = redirectUrl;
        }
      });
    };

    if (data?.viewer.isAdmin === true) {
      getAuth();
    }
  }, [data, getAuthCode]);

  if (data?.viewer.isAdmin === true) {
    return <div>Redirecting....</div>;
  } else if (data?.viewer.isAdmin === false) {
    return <div>You must be an admin to perform to authorize this app.</div>;
  } else if (data?.viewer.isAdmin === true && AuthCodeData.TalentechAuthorization) {
    return <div>No Talentech user found.</div>;
  } else {
    return <div>Authorizing....</div>;
  }
};

export default Talentech;
