import React, { useState } from 'react';

import Moment from 'react-moment';
import { Table, OverlayTrigger, Tooltip, Badge, Form } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import ConfirmationModal from '../elements/ConfirmationModal';

const getLocationBadge = (location) => {
  let badgeText = location?.name || location?.city || location?.country;

  return badgeText ? (
    <Badge variant="info" className="ml-1">
      {badgeText}
    </Badge>
  ) : null;
};

const ListJobs = ({ jobs, onJobActiveToggle, size, loading = false }) => {
  const [showConfirmationToggleModal, setShowConfirmationToggleModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  const toggleJobActiveStatus = (job) => {
    if (job.active === true) {
      setShowConfirmationToggleModal(true);
    } else {
      onJobActiveToggle(job);
    }
    setSelectedJob(job);
  };

  const handleJobToggleConfirm = () => {
    if (selectedJob) {
      onJobActiveToggle(selectedJob);
    }
    setSelectedJob(null);
    setShowConfirmationToggleModal(false);
  };

  const handleJobToggleClose = () => {
    setShowConfirmationToggleModal(false);
  };
  var small = true;
  if (size === 'small') small = false;

  return (
    <>
      <ConfirmationModal
        message={[
          <p key="a">
            You are about to deactivate the job <strong>{selectedJob ? selectedJob.title : ''}</strong>.
          </p>,
          'This means Hubert ',
          <strong key="b">will not invite, interview or score candidates in this job.</strong>,
        ]}
        show={showConfirmationToggleModal}
        onHide={handleJobToggleClose}
        onConfirm={handleJobToggleConfirm}
        confirmButtonText="Deactivate"
      />
      <Table hover borderless className="job-list">
        <thead>
          <tr className="text-center ">
            <th className="text-left px-1 ">Job</th>
            {/* <th style={{ width: '6rem' }}>Location</th> */}

            <OverlayTrigger
              placement={'left'}
              delay="800"
              overlay={<Tooltip id={`tooltip-info-Applicants`}>Total number of applications in this job.</Tooltip>}
            >
              <th style={{ width: '6rem' }}>Total Applicants</th>
            </OverlayTrigger>

            {small && (
              <OverlayTrigger
                placement={'left'}
                delay="800"
                overlay={
                  <Tooltip id={`tooltip-info-Invited`}>
                    Number of invited applicants that haven't finished the Hubert interview.
                  </Tooltip>
                }
              >
                <th>Invited Applicants</th>
              </OverlayTrigger>
            )}
            <OverlayTrigger
              placement={'left'}
              delay="800"
              overlay={
                <Tooltip id={`tooltip-info-NewLeads`}>
                  Number of applicants that have completed the interview and has fulfilled all requirements for the job.
                </Tooltip>
              }
            >
              <th>Hubert Leads</th>
            </OverlayTrigger>
            <OverlayTrigger
              placement={'left'}
              delay="800"
              overlay={
                <Tooltip id={`tooltip-info-Accepted`}>
                  Number of applicants that have completed the interview, fulfilled all requirements and have been
                  accepted by a recruiter.
                </Tooltip>
              }
            >
              <th>Accepted Leads</th>
            </OverlayTrigger>
            {small && (
              <OverlayTrigger
                placement={'left'}
                delay="800"
                overlay={<Tooltip id={`tooltip-info-Hired`}>Number of applicants that have been hired.</Tooltip>}
              >
                <th>Hired</th>
              </OverlayTrigger>
            )}

            <OverlayTrigger
              placement={'left'}
              delay="800"
              overlay={
                <Tooltip id={`tooltip-info-NotHired`}>
                  Number of applicants that have completed the interview, but haven't qualified for the job or has been
                  manually rejected by a recruiter.
                </Tooltip>
              }
            >
              <th>Not Hired</th>
            </OverlayTrigger>

            {small && <th style={{ width: '3rem' }}></th>}
            {small && <th>Created</th>}
            {/* <th>Users</th> */}
            {/* <th style={{ width: '50px' }}>Edit</th> */}
            {small && (
              <OverlayTrigger
                key={`job-overlay-info-close`}
                delay={500}
                placement={'left'}
                overlay={
                  <Tooltip id={`tooltip-close`}>
                    Hubert only invites, interviews and scores candidates in active jobs.
                  </Tooltip>
                }
              >
                <th>Active</th>
              </OverlayTrigger>
            )}
          </tr>
        </thead>
        <tbody>
          {loading &&
            Array.from({ length: 6 }, (_, index) => {
              const titleWidths = [95, 87, 100, 76, 95, 100];

              return (
                <tr key={`skeleton-${index}`}>
                  <td>
                    <Skeleton width={`${titleWidths[index]}%`} />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Skeleton width="24px" />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Skeleton width="24px" />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Skeleton width="24px" />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Skeleton width="24px" />
                  </td>
                  {small && (
                    <>
                      <td style={{ textAlign: 'center' }}>
                        <Skeleton width="24px" />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <Skeleton width="24px" />
                      </td>
                      <td></td>
                      <td style={{ textAlign: 'center' }}>
                        <Skeleton />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <Skeleton width="20px" height="20px" circle={true} />
                      </td>
                    </>
                  )}
                </tr>
              );
            })}

          {jobs && !small && jobs.length === 0 && (
            <tr>
              <td colSpan="5" className="text-left tx-1">
                No jobs yet.
              </td>
            </tr>
          )}

          {jobs && small && jobs.length === 0 && (
            <tr>
              <td colSpan="10">
                <span className="text-left tx-1">No jobs yet.</span>
              </td>
            </tr>
          )}

          {jobs &&
            jobs.map((job, index) => {
              return (
                <tr key={`job-${index}`} className="text-center border-top ">
                  <td className="text-left text-truncate ">
                    <NavLink
                      key={`job-link-${index}-${job._id}`}
                      exact
                      to={'/job/' + job.id}
                      className="text-decoration-none text-black px-1"
                    >
                      <OverlayTrigger
                        key={`job-overlay-${index}-${job._id}`}
                        delay={500}
                        placment={'auto-start'}
                        overlay={<Tooltip id={`tooltip-${job._id}`}>{job.title}</Tooltip>}
                      >
                        <span className="pr-1">{job.title}</span>
                      </OverlayTrigger>
                      {getLocationBadge(job.location)}
                    </NavLink>
                  </td>
                  <td className="text-center">
                    <strong>{job ? job.total.totalCount : 0}</strong>
                  </td>
                  {small && (
                    <td className="text-center color dark-grey">
                      <strong>{job ? job.new.totalCount : 0}</strong>
                    </td>
                  )}
                  <td className="color purple">
                    <strong>{job ? job.leads.totalCount : 0}</strong>
                  </td>
                  <td className="color green">
                    <strong>{job ? job.accepted.totalCount : 0}</strong>
                  </td>
                  {small && (
                    <td className="color purple">
                      <strong>{job ? job.hired.totalCount : 0}</strong>
                    </td>
                  )}
                  <td className="color red">
                    <strong>{job ? job.notHired.totalCount : 0}</strong>
                  </td>
                  {small && (
                    <>
                      <td></td>
                      <td>
                        <OverlayTrigger
                          key={`job-overlay-${index}-${job._id}-time`}
                          delay={500}
                          placment={'auto-start'}
                          overlay={
                            <Tooltip id={`tooltip-${job._id}`}>
                              <Moment unix date={job.createdAt / 1000} format="YYYY-MM-DD hh:mm" />
                            </Tooltip>
                          }
                        >
                          <Moment unix date={job.createdAt / 1000} format="YY.MM.DD" />
                        </OverlayTrigger>
                      </td>

                      <td>
                        <OverlayTrigger
                          key={`job-overlay-info-close`}
                          delay={500}
                          placment={'auto-start'}
                          overlay={
                            <Tooltip id={`tooltip-close`}>
                              This job is <strong>{job.active ? 'active' : 'inactive'}.</strong>
                              {job.active === false && (
                                <p>Hubert will not invite, interview or score candidates in inactive jobs.</p>
                              )}
                            </Tooltip>
                          }
                        >
                          <span>
                            <Form.Check
                              checked={job.active}
                              type="switch"
                              id={`custom-switch-${index}`}
                              onClick={(e) => {
                                toggleJobActiveStatus(job);
                              }}
                              onChange={() => {}}
                            />
                          </span>
                        </OverlayTrigger>
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
        </tbody>
        {!small && jobs && jobs.length !== 0 && (
          <tfoot>
            <tr>
              <td colSpan="5">
                <Link to={'/jobs'}>View all jobs...</Link>
              </td>
            </tr>
          </tfoot>
        )}
      </Table>
    </>
  );
};

export default ListJobs;
