import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import InterviewsList from './InterviewsList';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import { BULLHORN_JOB_SETTINGS_QUERY } from './queries';
import { BhTabs, useBhTabbedNavigation } from './BhNavigation';
import { INTERVIEW_FILTER_PRESETS } from './constants';
import SimpleLoader from '../../elements/SimpleLoader';
import BhWidgetSettings from './BhWidgetSettings';

function TemplateTitle({ selectedTemplate }) {
  return (
    <div>
      <h3>Selected template</h3>
      <h1>{selectedTemplate}</h1>
    </div>
  );
}

function ConfigureJob({ onClick }) {
  return (
    <Button className="bullhorn-interviews-list-action" title="Configure job" variant="secondary" onClick={onClick}>
      <FontAwesomeIcon icon={faCog} />
    </Button>
  );
}

function SendInterview({ onClick }) {
  return (
    <Button className="bullhorn-interviews-list-action" title="Send invite" variant="secondary" onClick={onClick}>
      <FontAwesomeIcon icon={faPaperPlane} />
    </Button>
  );
}

function InterviewsListContainer({ job }) {
  const { changeTab } = useBhTabbedNavigation();
  const [filterPreset, setFilterPreset] = useState(INTERVIEW_FILTER_PRESETS.LEADS_ONLY);

  const { data: bullhornInterviewsQuery, loading } = useQuery(
    gql`
      query BullhornInterviews($bullhornJobOrderId: Int!, $preset: BullhornInterviewFilterPreset) {
        bullhornInterviews(bullhornJobOrderId: $bullhornJobOrderId, preset: $preset) {
          nodes {
            job {
              id
            }
            id
            score
            status
            interviews {
              nodes {
                id
                state
                created_at
              }
            }
            candidate {
              firstName
              lastName
              name
              email
            }
          }
        }
      }
    `,
    { variables: { bullhornJobOrderId: job, preset: filterPreset } },
  );

  const { data: bullhornJobSettingsQuery, loading: bullhornJobSettingsLoading } = useQuery(
    BULLHORN_JOB_SETTINGS_QUERY,
    {
      variables: { bullhornJobOrderId: job },
    },
  );

  if (bullhornJobSettingsLoading) {
    return <SimpleLoader />;
  }

  if (!bullhornJobSettingsQuery?.bullhornJobSettings) {
    return <BhWidgetSettings job={job} />;
  }

  const hubertApplicationObjects = bullhornInterviewsQuery?.bullhornInterviews?.nodes ?? [];
  const selectedTemplate = bullhornJobSettingsQuery?.bullhornJobSettings?.templateName ?? `No template set`;

  return (
    <div className="bullhorn-interviews-list-container">
      <div className="bullhorn-interviews-list-header">
        <TemplateTitle selectedTemplate={selectedTemplate} />
        <div>
          <ConfigureJob onClick={() => changeTab(BhTabs.SETTINGS)} />
          <SendInterview onClick={() => changeTab(BhTabs.SEND_INTERVIEW)} />
        </div>
      </div>
      <InterviewsList
        applications={hubertApplicationObjects}
        loading={loading}
        filter={filterPreset}
        onFilterChange={setFilterPreset}
      />
    </div>
  );
}

export default InterviewsListContainer;
