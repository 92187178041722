import React from 'react';

import moment from 'moment';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const getTimeIcon = ({ time }) => {
  if (!time) time = moment();
  // function round(date, duration, method) {
  //   return moment(Math[method](+date / +duration) * +duration);
  // }
  // var roundedDate = round(time, moment.duration(30, 'minutes'), 'round');
  // const hour = roundedDate.format('h');
  // const minute = roundedDate.format('m');

  // var timeIcon = '';
  // var timeStrings = [
  //   'zero',
  //   'one',
  //   'two',
  //   'three',
  //   'four',
  //   'five',
  //   'six',
  //   'seven',
  //   'eight',
  //   'nine',
  //   'ten',
  //   'eleven',
  //   'twelve',
  // ];

  // if (minute === '30') timeIcon = 'clock-' + timeStrings[hour] + '-thirty';
  // else timeIcon = 'clock-' + timeStrings[hour];

  return (
    <OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip`}>{time.format('YYYY-MM-DD HH:mm')}</Tooltip>}>
      {/* <FontAwesomeIcon icon={['far', timeIcon]} fixedWidth className="my-auto" /> */}
      <FontAwesomeIcon icon={['far', 'clock']} fixedWidth className="my-auto" />
    </OverlayTrigger>
  );
};

export default getTimeIcon;

const GetTime = (time) => {
  var timeObject = moment(time);
  // console.log('time', timeObject.format('YYYY-MM-DD HH:mm'), 'in:', time);

  if (time) {
    return (
      // <OverlayTrigger
      //   placement="auto"
      //   overlay={<Tooltip id={`tooltip`}>{timeObject.format('YYYY-MM-DD HH:mm')}</Tooltip>}
      // >
      <Moment toNow ago date={timeObject} interval={10000} unit="minutes" />
      // </OverlayTrigger>
    );
  } else return null;
};

export { GetTime };
