import React, { useMemo } from 'react';

import Skeleton from 'react-loading-skeleton';
import { getRandomWidth } from '../common';

const ApplicantCardSkeleton = ({ select }) => {
  let randWidthName = useMemo(() => getRandomWidth(30, 150), []);
  let randWidthStatus = useMemo(() => getRandomWidth(30, 60), []);

  return (
    <div className={select ? 'applicant-list-item dark' : 'applicant-list-item shadow-sm' + ' pr-2'}>
      <div className="score">
        <Skeleton height="2rem" width="2rem" circle={true} />
      </div>
      <div className="pill-height align-self-center d-flex">
        <div>
          <Skeleton width={randWidthName} />
        </div>
        <div className="status-row">
          <span>
            <Skeleton width={randWidthStatus} />
          </span>
          <span className="time mr-2">
            <Skeleton width={'30px'} />
          </span>

          <span className="time">
            <Skeleton width={'30px'} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ApplicantCardSkeleton;
