import { gql, useQuery } from '@apollo/client';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import OpenApplication from './OpenApplication';

const NavbarTop = () => {
  return (
    <div className="navbar-top align-items-center m-0 px-2">
      <Row className="vertical-center">
        <Col>
          <NavLink activeClassName="active" exact to="/">
            <img src="/logo/LogoNaked@0.5x.png" className="navbar-logo py-3 mr-2 ml-1" alt="Hubert Logo" />
          </NavLink>
        </Col>
        <Col className="flex-grow d-lg-flex mx-0 px-0 justify-content-end" sm={2} lg={6}>
          {/* <User /> */}
        </Col>
      </Row>
    </div>
  );
};

const ContentNotAllwed = ({ text }) => {
  return (
    <>
      <Row className="vertical-center text-center h-100" style={{ minHeight: '200px' }}>
        <Col>
          <p>{text}</p>
        </Col>
      </Row>
    </>
  );
};

const OPEN_APPLICATION_QUERY = gql`
  query ($applicationId: String!) {
    checkOpenSummaryExpirationDate(applicationId: $applicationId) {
      expiry
      disabled
    }
  }
`;

const Loading = () => <ContentNotAllwed text={'Loading…'} />;

const OpenApplicationContainer = () => {
  let { id } = useParams();
  const { search } = useLocation();
  const [applicationId, setApplicationId] = useState(null);
  const [now, setNow] = useState(Date.now());
  const [isExpired, setIsExpired] = useState(null);

  const { data } = useQuery(OPEN_APPLICATION_QUERY, {
    variables: { applicationId: applicationId },
    skip: applicationId ? false : true,
  });

  useEffect(() => {
    try {
      if (id) {
        setApplicationId(id);
        if (data && data.checkOpenSummaryExpirationDate) {
          setIsExpired(false);
        }
      } else if (isExpired === null && applicationId === null && search.includes('token')) {
        const token = search.split('=')[1];
        const { exp, application_id } = jwtDecode(token);
        setApplicationId(application_id);
        setIsExpired(now <= exp * 1000);
      }
    } catch (e) {
      return <ContentNotAllwed text={'Access Denied: failed expiry.'} />;
    }
  }, [id, applicationId, data]);

  return (
    <>
      <NavbarTop />
      {(() => {
        if (applicationId && isExpired === false) {
          return <OpenApplication applicationId={applicationId} whileLoading={<Loading />} />;
        } else if (isExpired === true) {
          return <ContentNotAllwed text={'Open summary expired.'} />;
        } else {
          return <Loading />;
        }
      })()}
    </>
  );
};

export default OpenApplicationContainer;
