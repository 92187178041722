import { useState } from 'react';

import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { toast } from 'sonner';

import { useMutation, gql } from '@apollo/client';

const REINVITE = gql`
  mutation sendInterviewInvitation($applicationId: String!) {
    sendInterviewInvitation(applicationId: $applicationId)
  }
`;

const ReInvite = (props) => {
  const [reinviteApplication, { loading }] = useMutation(REINVITE);
  const [hover, setHover] = useState(false);
  const [testTimeOut, setTestTimeOut] = useState(null);

  const application = props.application;
  const reinviteClick = (e) => {
    console.log(application, application.id);
    if (application.job.id && application.id) {
      reinviteApplication({
        variables: {
          applicationId: application.id,
        },
      })
        .then((response) => {
          if (response.data && response.data.sendInterviewInvitation) {
            setHover(false);
            reinviteToast(true);
          }
        })
        .catch((err) => {
          console.error(err);
          // TODO: Show error message when global error toast is removed.
          // reinviteToast(false, err.message);
        });
    }
  };
  const reinviteToast = (success, errorMessage = '') => {
    if (success) {
      toast.success('Success', { description: 'Invite has been resent' });
    } else {
      toast.error('Failed to send a new invitation', { description: errorMessage });
    }
  };

  return (
    <Button
      className={props.className}
      variant="secondary"
      // onClick={() => setClicked(!clicked)}
      disabled={loading}
      onMouseOver={() => {
        setHover(true);
        clearTimeout(testTimeOut);
      }}
      onMouseLeave={() => {
        setTestTimeOut(
          setTimeout(() => {
            setHover(false);
          }, 1000),
        );
      }}
      onClick={
        hover
          ? reinviteClick
          : () => {
              console.log('Nej ');
            }
      }
    >
      {hover && <span>Reinvite Candidate</span>}
      {!hover && (
        <span>
          <FontAwesomeIcon icon={['far', 'paper-plane']} fixedWidth className="my-auto" />
        </span>
      )}
    </Button>
  );
};

export default ReInvite;
