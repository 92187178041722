import { useEffect } from 'react';

let messenger = (...args) => {
  console.warn('ZendeskSDK not initalize', { args });
};

export class ZendeskMessengerApi {
  static hide() {
    messenger('hide');
  }

  static show() {
    messenger('show');
  }

  static async login(token) {
    messenger('loginUser', function (callback) {
      callback(token);
    });
  }

  static logout() {
    messenger('logoutUser');
  }
}

const ZendeskWidget = ({ zendeskKey }) => {
  useEffect(() => {
    if (zendeskKey) {
      const script = document.createElement('script');
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
      script.id = 'ze-snippet';
      script.async = true;
      script.onload = () => {
        messenger = (...args) => window.zE('messenger', ...args);
      };
      document.body.appendChild(script);

      return () => {
        delete window.zE;
        delete window.zESettings;
        document.body.removeChild(script);
      };
    }
  }, [zendeskKey]);

  return null;
};

export default ZendeskWidget;
