import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'sonner';
import useSearchParams from '../hooks/useSearchParams';

import Title from '../elements/Title.js';
import ListJobs from '../jobs/ListJobs.js';
import PageHeader from './PageHeader.js';

import Pagination from '../elements/Pagination';
import { Error } from './Error';

const JOBS_QUERY = gql`
  query JOBS($limit: Int, $skip: Int) {
    allJobs(sort: { field: _id, order: DESC }, limit: $limit, skip: $skip) {
      totalCount
      nodes {
        title
        id
        company
        active
        createdAt
        threshold
        pinned
        location {
          id
          name
          city
          country
        }
        new: applications(filter: { stage: { equals: null } }) {
          totalCount
        }
        leads: applications(filter: { stage: { equals: 2 } }) {
          totalCount
        }
        accepted: applications(filter: { stage: { equals: 3 } }) {
          totalCount
        }
        hired: applications(filter: { stage: { equals: 4 } }) {
          totalCount
        }
        notHired: applications(filter: { stage: { equals: 5 } }) {
          totalCount
        }

        total: applications {
          totalCount
        }
      }
    }
  }
`;

const EDIT_JOB = gql`
  mutation JobSetActive($jobId: String!, $jobActive: Boolean!) {
    jobSetActive(jobId: $jobId, jobActive: $jobActive) {
      __typename
      id
      active
    }
  }
`;

export const SignInUrl = (location) => {
  const returnTo = `${location.pathname}${location.search}`;
  return `/signin?redirect=${returnTo}`;
};

export const Jobs = () => {
  const JOBS_PER_PAGE = 15;

  const query = useSearchParams();
  const queryPage = parseInt(query.get('page')) || 1;

  const [currentPage, setCurrentPage] = useState(queryPage);
  const [pages, setPages] = useState([]);

  const skip = JOBS_PER_PAGE * (currentPage - 1);

  const history = useHistory();
  const location = useLocation();

  const { data, error, loading } = useQuery(JOBS_QUERY, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: false,
    variables: { skip: skip, limit: JOBS_PER_PAGE },
  });

  const { data: pinnedJobsData } = useQuery(
    gql`
      query pinnedJobs {
        pinnedJobs: viewer {
          id
          pinnedJobs {
            title
            id
            company
            active
            createdAt
            threshold
            pinned
            location {
              id
              name
              address
            }
            new: applications(filter: { stage: { equals: null } }) {
              totalCount
            }
            leads: applications(filter: { stage: { equals: 2 } }) {
              totalCount
            }
            accepted: applications(filter: { stage: { equals: 3 } }) {
              totalCount
            }
            hired: applications(filter: { stage: { equals: 4 } }) {
              totalCount
            }
            notHired: applications(filter: { stage: { equals: 5 } }) {
              totalCount
            }

            total: applications {
              totalCount
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const onPageChange = (e, page) => {
    const pageNum = parseInt(page) || 0;
    setCurrentPage(pageNum);
    history.push({
      pathname: '/jobs',
      search: `?page=${pageNum}`,
    });
  };

  useEffect(() => {
    if (data?.allJobs.totalCount !== undefined) {
      const numPages = Math.ceil(data?.allJobs.totalCount / JOBS_PER_PAGE);
      setPages(Array.from({ length: numPages }, (_, i) => i + 1) || []);
    }
  }, [data?.allJobs.totalCount]);

  const [setJobActiveStatus, { error: mutationError }] = useMutation(EDIT_JOB, {
    update: (cache, { data: { jobSetActive } }) => {
      const cacheId = cache.identify(jobSetActive);

      cache.modify({
        id: cacheId,
        broadcast: false,
        fields: {
          active() {
            return jobSetActive.active;
          },
        },
      });
    },
  });

  const toggleJobActive = (job) => {
    setJobActiveStatus({
      variables: {
        jobId: job.id,
        jobActive: !Boolean(job.active),
      },
      optimisticResponse: {
        jobSetActive: {
          __typename: 'Job',
          id: job.id,
          active: !Boolean(job.active),
        },
      },
    }).catch((err) => {
      toast.error(`Failed to change Job Status for ${job.title}`, {
        duration: 6000,
        description: err.message,
      });
      console.error(err.message);
    });
  };

  return (
    <div className="jobs">
      <Title title={'Jobs'} />

      <Container fluid>
        <PageHeader className="">
          <PageHeader.Title className="px-0" style={{ marginLeft: '0rem!important' }}>
            Jobs
          </PageHeader.Title>
          <PageHeader.Buttons></PageHeader.Buttons>
        </PageHeader>

        <Row className="border-grey-top pt-3 p-1">
          {pinnedJobsData?.pinnedJobs?.pinnedJobs?.length > 0 && (
            <Col>
              <h2>Pinned Jobs</h2>
              <ListJobs onJobActiveToggle={toggleJobActive} jobs={pinnedJobsData.pinnedJobs.pinnedJobs} />
            </Col>
          )}
        </Row>
        <Row className="p-1">
          <Col>
            {Error(error, location)}
            {mutationError && (
              <Alert variant="danger">
                Something went wrong. Please try again or contact an administrator if the error persists.
              </Alert>
            )}

            <ListJobs
              onJobActiveToggle={toggleJobActive}
              jobs={data && !loading ? data.allJobs.nodes : null}
              loading={loading}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {!loading && pages.length > 0 && (
              <Pagination
                style={{ justifyContent: 'center' }}
                pages={pages}
                selectedIndex={currentPage - 1}
                onChange={onPageChange}
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
