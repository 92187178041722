import axios from 'axios';
import { useEffect, useState } from 'react';

export const VersionCheck = () => {
  const [version, setVersion] = useState({ name: '', updateRequired: false });
  useEffect(() => {
    let t = null;
    const doVersionCheck = () => {
      if (document.hidden) return;

      axios
        .head('/')
        .then(
          (x) => {
            setVersion((v) => {
              const next = x.headers.get('X-Version') ?? v.name;
              return { name: next, updateRequired: v.name !== next && v.name !== '' };
            });
          },
          () => {},
        )
        .then(() => {
          t = setTimeout(doVersionCheck, 5000);
        });
    };
    document.addEventListener('visibilitychange', () => {
      clearTimeout(t);
      doVersionCheck();
    });
    doVersionCheck();
    return () => {
      clearTimeout(t);
      document.removeEventListener('visibilitychange', doVersionCheck);
    };
  }, []);

  useEffect(() => {
    if (version && version.updateRequired) window.location.reload();
  }, [version]);
  return (
    version && (
      <span style={{ position: 'fixed', bottom: 0, 'z-index': 1020, 'font-size': '8pt', color: 'silver' }}>
        {version.name}
      </span>
    )
  );
};
