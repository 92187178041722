import { gql, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import BackToHubert from '../../navbar/BackToHubert';

import Title from '../../elements/Title';

const yup = require('yup');

const FORGOT_PASSWORD = gql`
  mutation ForgottPassword($email: String!) {
    ForgotPasswordMagicLink(email: $email) {
      __typename
      ... on User {
        email
      }

      ... on UserNotActivated {
        email
      }
    }
  }
`;

const RESEND_INVITATION = gql`
  mutation ResendInvitation($email: String!) {
    ReinviteToOrganization(input: { email: $email })
  }
`;

const Forgot = ({ location }) => {
  const history = useHistory();

  const [validated, setValidated] = useState(false);
  const [checkValidated, setCheckValidated] = useState(false);

  const [email, setEmail] = useState('');
  const [loginError, setLoginError] = useState();
  const [sent, setSent] = useState(false);
  const [notActivated, setNotActivated] = useState(false);

  const [ForgotPassword, { data, loading }] = useMutation(FORGOT_PASSWORD, {
    notifyOnNetworkStatusChange: true,
  });

  const [ResendInvitation, { data: ResendInvitationData, loading: ResendInvitationLoading }] = useMutation(
    RESEND_INVITATION,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    if (!location.state) return;

    const state = location.state.state;
    const email = location.state.email;

    setValidated(true);

    if (state === 'not-activated' && email) {
      setNotActivated(true);
      setEmail(email);
    }
  }, []);

  useEffect(() => {
    if (data && data.ForgotPasswordMagicLink) {
      switch (data.ForgotPasswordMagicLink.__typename) {
        case 'User': {
          if (data.ForgotPasswordMagicLink.email === email) {
            setSent(true);
            setTimeout(() => {
              history.push('/');
            }, 3000);
          } else {
            setSent(false);
          }
          break;
        }

        case 'UserNotActivated': {
          setNotActivated(true);
          break;
        }

        default: {
          break;
        }
      }
    }
  }, [data, email]);

  const manualValidation = () => {
    const valid = yup.string().email().isValidSync(email);

    return valid;
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    setLoginError();

    if (form.checkValidity() === false && manualValidation() === false) {
      event.stopPropagation();
      setValidated(false);
    } else {
      setValidated(true);

      if (!notActivated) {
        ForgotPassword({
          variables: {
            email: email.trim().toLowerCase(),
          },
        })
          .then((response) => {
            console.log('Skickat', response);
          })
          .catch((err) => {
            console.error(err);
            setLoginError(`Could not find account with email: ${email}`);
          });
      } else {
        ResendInvitation({
          variables: {
            email: email.trim().toLowerCase(),
          },
        })
          .then(() => {
            setSent(true);
            setTimeout(() => {
              history.push('/');
            }, 4000);
          })
          .catch((error) => {});
      }
    }
  };

  const checkForm = (event) => {
    const form = event.currentTarget;
    form.checkValidity() && manualValidation() ? setValidated(true) : setValidated(false);

    if (event.target.id === 'email') {
      setEmail(event.target.value);
    } else console.log(event);
  };

  return (
    <Container fluid className="vh-100">
      <Title title={'Reset Password'} />

      <BackToHubert />
      <Row className="d-flex align-items-center justify-content-center h-100 ">
        <Col lg={5} s={12} className="p-xs-0 m-1 p-md-3 sign-in">
          <Row>
            <Col className="d-flex justify-content-center align-items-center text-center">
              <div className="pb-4 w-75" style={{ maxWidth: '410px' }}>
                <div className="">
                  <img src="/logo/hubert_logo_lockup_dark@2x.png" className="logo" alt="Hubert Logo" />
                  <h2>Reset your password</h2>
                </div>
                {loginError && <Alert variant="danger">{loginError}</Alert>}
                {notActivated && (
                  <Alert variant="info">
                    You need to activate your account. Press the button below to request a new activation email. If you
                    cannot find your activation email in your inbox, please check your spam folder.
                  </Alert>
                )}
                <Form noValidate validated={checkValidated} onSubmit={handleSubmit} autoComplete="off">
                  <Form.Group className="my-4">
                    <Form.Control
                      type="email"
                      q
                      placeholder="Email"
                      id="email"
                      required
                      value={email}
                      disabled={notActivated || loading}
                      onChange={checkForm}
                      className="text-center"
                    />
                    <Form.Control.Feedback type="invalid">Please enter a valid email.</Form.Control.Feedback>
                  </Form.Group>

                  {notActivated ? (
                    <Button
                      type="submit"
                      block
                      className="my-4"
                      variant={validated ? 'primary' : 'secondary'}
                      disabled={loading || !validated || sent}
                    >
                      {ResendInvitationLoading ? (
                        <FontAwesomeIcon icon={['far', 'spinner']} spin fixedWidth size="lg" className="color" />
                      ) : sent ? (
                        'Email sent'
                      ) : (
                        'Send new activation email'
                      )}
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      block
                      className="my-4"
                      variant={validated ? 'primary' : 'secondary'}
                      disabled={loading || !validated || sent || notActivated}
                    >
                      {loading ? (
                        <FontAwesomeIcon icon={['far', 'spinner']} spin fixedWidth size="lg" className="color" />
                      ) : sent ? (
                        'Email sent'
                      ) : (
                        'Send recovery link'
                      )}
                    </Button>
                  )}
                  {/* <p className="text-center">or</p> */}
                </Form>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Forgot;
