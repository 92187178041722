import { gql, useQuery } from '@apollo/client';

const ZENDESK_AUTH = gql`
  query ZendeskAuth {
    ZendeskAuthentication {
      token
    }
  }
`;

const Zendesk = (props) => {
  const { data, error, refetch, loading } = useQuery(ZENDESK_AUTH);
  const returnTo = new URLSearchParams(props.location.search).get('return_to');

  if (loading) return <div>Awaiting authentication...</div>;
  if (!data?.ZendeskAuthentication) return <div>Authentication failure.</div>;

  return (
    <form
      action="https://hubert9080.zendesk.com/access/jwt"
      method="post"
      ref={(node) => node?.submit()}
      style={{ visibility: 'hidden' }}
    >
      <input type="hidden" name="jwt" value={data.ZendeskAuthentication.token}></input>
      <input type="hidden" name="return_to" value={returnTo}></input>
      <input type="submit" value="Go to Zendesk"></input>
    </form>
  );
};

export default Zendesk;
