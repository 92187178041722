const Divider = ({ variant }) => {
  if (variant === 'middle') {
    return <hr className="divider--middle" />;
  }

  //Default
  return <div className="divider fullWidth"></div>;
};

export default Divider;
