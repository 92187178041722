import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
// import { DevTool } from '@hookform/devtools';

const SimpleLoader = () => (
  <div className="loader">
    <FontAwesomeIcon icon={['far', 'spinner']} spin size="lg" className="color dark-grey" />
  </div>
);

const PLACEHOLDER_CANDIDATE_OPTION = -1;

function CandidateOption({ candidate }) {
  const isPlaceholderOption = candidate.id === PLACEHOLDER_CANDIDATE_OPTION;

  if (isPlaceholderOption) {
    return (
      <option key={candidate.id} value={candidate.id} disabled hidden>
        Select a candidate
      </option>
    );
  }

  const {
    id,
    candidate: { firstName, lastName, email },
    application,
  } = candidate;

  const invited = application?.interviews.nodes.length > 0;

  return (
    <option key={id} value={id} disabled={invited}>
      {firstName} {lastName} ({email}) {invited ? '(Invited)' : ''}
    </option>
  );
}

function ThisForm({ candidates, template, onSubmit }) {
  const {
    register,
    // control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      template: template.id,
      bhJobSubmission: PLACEHOLDER_CANDIDATE_OPTION,
    },
  });

  const sortedCandidates = [...candidates].sort((a, b) =>
    `${a.candidate.firstName} ${a.candidate.lastName}`.localeCompare(
      `${b.candidate.firstName} ${b.candidate.lastName}`,
    ),
  );

  const candidateOptions = [{ id: PLACEHOLDER_CANDIDATE_OPTION }, ...sortedCandidates].map((candidate) => (
    <CandidateOption key={candidate.id} candidate={candidate} />
  ));

  return (
    <>
      {/* <DevTool control={control} placement="top-right" /> */}
      <form className="small send-interview-form" onSubmit={handleSubmit(onSubmit)}>
        <Form.Group>
          <Form.Label className="label-font">Template</Form.Label>
          <Form.Control type="select" as="select" {...register('template')} readOnly>
            <option key={template.id} value={template.id}>
              {template.name}
            </option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label className="label-font">Candidate</Form.Label>
          <Form.Control
            type="select"
            as="select"
            {...register('bhJobSubmission', {
              valueAsNumber: true,
              validate: (value) => value !== PLACEHOLDER_CANDIDATE_OPTION || 'Candidate is required',
            })}
            isInvalid={errors.bhJobSubmission}
          >
            {candidateOptions}
          </Form.Control>
          <Form.Control.Feedback type="invalid">{errors.bhJobSubmission?.message}</Form.Control.Feedback>
        </Form.Group>
        <Button type="submit" block className="submit-button my-4">
          Send invite
        </Button>
      </form>
    </>
  );
}

function SendInterviewForm({ job, settings }) {
  const { loading: candidatesLoading, data: getBhJobSubmissionsResponse } = useQuery(
    gql`
      query BullhornJobSubmissions($bullhornJobOrderId: Int) {
        bullhornJobSubmissions(bullhornJobOrderId: $bullhornJobOrderId) {
          id
          candidate {
            id
            firstName
            lastName
            email
          }
          application {
            interviews {
              nodes {
                id
                state
              }
            }
          }
        }
      }
    `,
    {
      variables: { bullhornJobOrderId: job },
    },
  );

  const candidates = getBhJobSubmissionsResponse?.bullhornJobSubmissions ?? [];

  const submittedCandidate = useRef(null);

  const [sendInterview, { loading: sendInterviewSubmitting, data: sendInterviewResponse }] = useMutation(gql`
    mutation BullhornSendInterview($templateId: String, $bullhornJobSubmissionId: Int) {
      bullhornSendInterview(templateId: $templateId, bullhornJobSubmissionId: $bullhornJobSubmissionId)
    }
  `);

  const onSubmit = (values) => {
    submittedCandidate.current = values.bhJobSubmission;

    return sendInterview({
      variables: { templateId: values.template, bullhornJobSubmissionId: values.bhJobSubmission },
    });
  };

  if (candidatesLoading || sendInterviewSubmitting) {
    return <SimpleLoader />;
  }

  if (sendInterviewResponse) {
    const selectedBhJobSubmission = candidates.find((candidate) => candidate.id === submittedCandidate.current);

    const successMessage = (
      <>
        Interview for <b>{settings.templateName}</b> successfully sent to{' '}
        <b>{`${selectedBhJobSubmission.candidate.firstName} ${selectedBhJobSubmission.candidate.lastName}`}</b>{' '}
        {`(${selectedBhJobSubmission.candidate.email})`}
      </>
    );

    return (
      <div className="success">
        <FontAwesomeIcon className="icon" icon={faCheckCircle} size="5x" />
        <div className="message">
          <h1>Interview sent!</h1>
          <h2 className="small">{successMessage}</h2>
        </div>
      </div>
    );
  }

  return (
    <ThisForm
      candidates={candidates}
      template={{ id: settings.templateId, name: settings.templateName }}
      onSubmit={onSubmit}
    />
  );
}

export default SendInterviewForm;
