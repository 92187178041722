import { gql, useMutation } from '@apollo/client';
import { useContext, useEffect, useReducer } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

import { Formik } from 'formik';
import jwtDecode from 'jwt-decode';
import * as yup from 'yup';
import { setAccessToken } from '../../accesstoken';
import LocaleContext from '../../hooks/LocaleContext';
import BackToHubert from '../../navbar/BackToHubert';

import { PasswordRegex } from '../../../utils/security';
import Title from '../../elements/Title';

const ACCOUNT_ACTIVATION = gql`
  mutation ACCOUNT_ACTIVATION(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $token: String!
  ) {
    AccountActivationMutation(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      token: $token
    ) {
      accessToken
      user {
        firstName
        lastName
      }
    }
  }
`;

const initState = {
  email: '',
  userId: '',
  tokenError: '',
  password: null,
  retypedPassword: '',
  validated: false,
  passwordValidated: false,
  loading: false,
  error: false,
  disabled: false,
  info: false,
  showForm: true,
};

function reducer(state, action) {
  switch (action.type) {
    case 'setDisabled':
      return { ...state, disabled: action.disabled };
    case 'setJwt':
      return {
        ...state,
        firstName: action.jwtData.user.firstName,
        lastName: action.jwtData.user.lastName,
        email: action.jwtData.user.email,
        jwtData: action.jwtData,
        exp: action.jwtData.exp * 1000,
      };
    case 'setShowForm':
      return {
        ...state,
        showForm: action.showForm,
      };
    case 'setPassword':
      return {
        ...state,
        password: action.password,
      };
    case 'setFirstName':
      return {
        ...state,
        firstName: action.firstName,
      };
    case 'setLastName':
      return {
        ...state,
        lastName: action.lastName,
      };
    case 'setRetypedPassword':
      return {
        ...state,
        retypedPassword: action.retypedPassword,
      };
    case 'setValidated':
      return {
        ...state,
        validated: action.validated,
      };
    case 'setPasswordValidated':
      return {
        ...state,
        passwordValidated: action.validated,
      };
    case 'setLoading':
      return {
        ...state,
        loading: action.loading,
      };
    case 'setTokenError':
      return {
        ...state,
        tokenError: action.error,
      };
    case 'setInfo':
      return {
        ...state,
        info: action.info,
      };

    default:
      throw new Error(`No ${action.type} is defined`);
  }
}

const Signup = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const { setLoggedIn } = useContext(LocaleContext);

  const history = useHistory();
  let query = useQuery();

  const magicLink = query.get('token');
  const [activateAccount] = useMutation(ACCOUNT_ACTIVATION);

  useEffect(() => {
    const jwt = magicLink ? jwtDecode(magicLink) : false;
    if (jwt) {
      dispatch({ type: 'setJwt', jwtData: jwt });
      dispatch({ type: 'setTokenError', error: '' });
    } else {
      dispatch({ type: 'setTokenError', error: 'Invalid token' });
    }
    setLoggedIn(false);
    setAccessToken('');
  }, []);

  const handleSubmit = (e) => {
    // const form = event.currentTarget;
    // event.preventDefault();
    if (e.email && e.password.length >= 6 && e.firstName && e.lastName && magicLink) {
      dispatch({ type: 'setLoading', loading: true });
      activateAccount({
        variables: {
          email: e.email,
          password: e.password,
          firstName: e.firstName.trim(),
          lastName: e.lastName.trim(),
          token: magicLink,
        },
      })
        .then((response) => {
          dispatch({ type: 'setDisabled', disabled: true });

          dispatch({ type: 'setPassword', password: '' });
          dispatch({ type: 'setRetypedPassword', retypedPassword: '' });
          dispatch({ type: 'setLoading', loading: false });

          // if (response && data) {
          console.log('data', response.data);
          console.log(response.data.AccountActivationMutation.accessToken);

          setAccessToken(response.data.AccountActivationMutation.accessToken);
          setLoggedIn(true);
          dispatch({ type: 'setInfo', info: 'Successfully created user!' });
          dispatch({ type: 'setShowForm', showForm: false });
          setTimeout(() => dispatch({ type: 'setInfo', info: 'Logging in to Hubert..' }), 2000);
          // }
          setTimeout(() => (query.get('redirect') ? history.push(query.get('redirect')) : history.push('/')), 5000);
          // }
        })
        .catch((err) => {
          console.error(err);
          dispatch({ type: 'setTokenError', error: err.message });
          dispatch({ type: 'setPassword', password: '' });
          dispatch({ type: 'setRetypedPassword', retypedPassword: '' });
        });
    } else dispatch({ type: 'setTokenError', error: 'Form missing input fields' });
  };

  return (
    <Container fluid className="vh-100">
      <BackToHubert />
      <Title title={'Sign-Up'} />
      <Row className="d-flex align-items-center justify-content-center h-100">
        <Col lg={5} s={12} className="p-xs-0 m-1 p-md-3 sign-in">
          <Row>
            <Col className="d-flex justify-content-center align-items-center">
              <div className="">
                <div className="text-center">
                  <img src="/logo/hubert_logo_lockup_dark@2x.png" className="logo" alt="Hubert Logo" />
                  <h2>Sign up</h2>
                  {state.tokenError && <Alert variant="danger">{state.tokenError}</Alert>}
                  {state.info && <Alert variant="success">{state.info}</Alert>}
                  {/* {state && (
                    <Alert variant={new Date() < new Date(mindre) ? 'success' : 'danger'}>
                      {new Date(mindre).toLocaleString()}
                    </Alert>
                  )} */}
                  {state.jwtData && state.jwtData.invitedBy.firstName && state.showForm ? (
                    <Alert variant="info" className="text-center">
                      You have been invited{' '}
                      {state.jwtData.invitedBy && (
                        <span>
                          by <i className="font-weight-bold">{state.jwtData.invitedBy.firstName} </i>
                        </span>
                      )}
                      {state.jwtData.invitedBy && !state.jwtData.invitedBy.company && (
                        <span>to start collaborating together with Hubert. </span>
                      )}
                      {state.jwtData.invitedBy && state.jwtData.invitedBy.company && (
                        <span>
                          to start collaborating together with Hubert at{' '}
                          <i className="font-weight-bold">{state.jwtData.invitedBy.company}</i>.{' '}
                        </span>
                      )}
                      {/* {state.jwtData.invitedBy.firstName} have started your sign up, */}
                      Fill out the form and choose a password to activate your account.
                    </Alert>
                  ) : (
                    <>
                      {state.jwtData && state.jwtData.client && state.showForm && (
                        <Alert variant="info" className="text-center">
                          You have been invited to start collaborating together with Hubert at{' '}
                          <i className="font-weight-bold">{state.jwtData.client.org_name}</i>. Fill out the form and
                          choose a password to activate your account.
                        </Alert>
                      )}
                    </>
                  )}
                </div>
                {state.jwtData && state.showForm && (
                  <SetupForm state={state} handleSubmit={handleSubmit} handleChange={console.log} />
                )}

                {/* <pre>{JSON.stringify(state.jwtData, null, 2)}</pre> */}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Signup;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SetupForm = ({ state, handleSubmit, handleChange }) => {
  const schema = yup.object().shape({
    firstName: yup.string().min(2).required('You must enter a name'),
    lastName: yup.string().min(2).required('You must enter a name'),
    email: yup.string().email().required(),
    password: yup
      .string()
      .required('Enter a password')
      .matches(
        PasswordRegex,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special character (@ $ ! % * # ? &)',
      ),
    passwordConfirmation: yup
      .string()
      .required('You have to enter a password')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  });
  // Pass the useFormik() hook initial form values and a submit function that will
  //    // be called when the form is submitted
  //    const formik = useFormik({
  //        initialValues:{
  //         firstName: state.firstName,
  //         lastName: state.lastName,
  //         terms: false,
  //       },
  //             validationSchema:{schema}
  // ,
  //      onSubmit: values => {
  //        alert(JSON.stringify(values, null, 2));
  //      },
  //    });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      handleChange={handleChange}
      // onSubmit={async (values) => {
      //   await new Promise((r) => setTimeout(r, 500));
      //   console.log
      // }}
      initialValues={{
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        password: '',
        passwordConfirmation: '',
      }}
    >
      {({ handleChange, handleSubmit, handleBlur, values, touched, isValid, errors }) => (
        <>
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-2" controlId="formEmail">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="input"
                className="email-link"
                readOnly
                value={state.jwtData && state.jwtData.user.email}
              />
            </Form.Group>
            <Form.Group controlId="signupFirstName">
              <Form.Label>First name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                isInvalid={!!errors.firstName}
                isValid={touched.firstName && !errors.firstName}
              />
              <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>

              {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
            </Form.Group>
            <Form.Group controlId="signupLastName">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                isInvalid={!!errors.lastName}
                isValid={touched.lastName && !errors.lastName}
              />
              <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="signupPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                name="password"
                value={values.password}
                onChange={handleChange}
                isInvalid={!!errors.password}
                isValid={touched.password && !errors.password}
              />

              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="signupRePassword">
              <Form.Label>Retype password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Retype Password"
                name="passwordConfirmation"
                value={values.passwordConfirmation}
                onChange={handleChange}
                isInvalid={!!errors.passwordConfirmation}
                isValid={touched.passwordConfirmation && !errors.passwordConfirmation}
              />
              <Form.Control.Feedback type="invalid">{errors.passwordConfirmation}</Form.Control.Feedback>
            </Form.Group>
            <Button type="submit" block className="my-4" variant={isValid ? 'primary' : 'secondary'}>
              {/* {state.loading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                </>
              ) : (
                'Set Password'
              )} */}
              Create Account
            </Button>
          </Form>
        </>
      )}
    </Formik>
  );
};
