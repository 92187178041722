import { gql, useMutation } from '@apollo/client';
import jwtDecode from 'jwt-decode';
import { useContext, useEffect, useReducer } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { setAccessToken } from '../../accesstoken';
import LocaleContext from '../../hooks/LocaleContext';
import BackToHubert from '../../navbar/BackToHubert';

import Title from '../../elements/Title';

import { Formik } from 'formik';
import * as yup from 'yup';
import { PasswordRegex } from '../../../utils/security';

// const SET_NEW_PASSWORD = gql`
//   mutation SetPassword($password: String!, $token:String!) {
//     SetPassword(password: $password, token: $token) {
//    	  token
//   }
// `;

const SET_NEW_PASSWORD = gql`
  mutation SetPassword($password: String!, $token: String!) {
    SetPassword(token: $token, password: $password) {
      accessToken
      user {
        firstName
        lastName
      }
    }
  }
`;

const initState = {
  email: '',
  userId: '',
  tokenError: '',
  password: '',
  retypedPassword: '',
  validated: false,
  loading: false,
  error: false,
  disabled: false,
  info: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'setDisabled':
      return { ...state, disabled: action.disabled };
    case 'setJwt':
      return {
        ...state,
        userId: action.jwtData.userId,
        email: action.jwtData.email,
        exp: action.jwtData.exp * 1000,
      };
    case 'setPassword':
      return {
        ...state,
        password: action.password,
      };
    case 'setRetypedPassword':
      return {
        ...state,
        retypedPassword: action.retypedPassword,
      };
    case 'setValidated':
      return {
        ...state,
        validated: action.validated,
      };
    case 'setLoading':
      return {
        ...state,
        loading: action.loading,
      };
    case 'setTokenError':
      return {
        ...state,
        tokenError: action.error,
      };
    case 'setInfo':
      return {
        ...state,
        info: action.info,
      };

    default:
      throw new Error(`No ${action.type} is defined`);
  }
}

const SetNewPassword = () => {
  const [states, dispatch] = useReducer(reducer, initState);
  const { setLoggedIn } = useContext(LocaleContext);

  const history = useHistory();
  let query = useQuery();
  const magicLink = query.get('token');
  const [SetPassword] = useMutation(SET_NEW_PASSWORD);

  useEffect(() => {
    const jwt = magicLink ? jwtDecode(magicLink) : false;
    if (jwt) {
      dispatch({ type: 'setJwt', jwtData: jwt });
      dispatch({ type: 'setTokenError', error: '' });
    } else {
      dispatch({ type: 'setTokenError', error: 'Invalid token' });
    }
    setLoggedIn(false);
    setAccessToken('');
  }, []);

  const handleSubmit = async (event) => {
    console.log(event);
    dispatch({ type: 'setLoading', loading: true });

    SetPassword({
      variables: {
        password: event.password,
        token: magicLink,
      },
    })
      .then((response) => {
        dispatch({ type: 'setDisabled', disabled: true });

        dispatch({ type: 'setLoading', loading: false });

        setAccessToken(response.data.SetPassword.accessToken);
        setLoggedIn(true);
        dispatch({ type: 'setInfo', info: 'Successfully changed password!' });
        setTimeout(() => dispatch({ type: 'setInfo', info: 'Logging in to Hubert..' }), 2000);
        // }
        setTimeout(() => (query.get('redirect') ? history.push(query.get('redirect')) : history.push('/')), 5000);
        // }
      })
      .catch((err) => {
        console.error(err);
        dispatch({ type: 'setTokenError', error: err.message });
      });
  };

  return (
    <Container fluid className="vh-100">
      <Title title={'Forgot Password'} />

      <BackToHubert />
      <Row className="d-flex align-items-center justify-content-center h-100 ">
        <Col lg={5} s={12} className="p-xs-0 m-1 p-md-3 sign-in">
          <Row>
            <Col className="d-flex justify-content-center align-items-center text-center">
              <div className="pb-4 w-75" style={{ maxWidth: '410px' }}>
                <div className="">
                  <img src="/logo/hubert_logo_lockup_dark@2x.png" className="logo" alt="Hubert Logo" />
                  <h2>Set new password</h2>
                </div>
                {states.tokenError && <Alert variant="danger">{states.tokenError}</Alert>}
                {states.info && <Alert variant="success">{states.info}</Alert>}
                {/* {state && (
                  <Alert variant={new Date() < new Date(mindre) ? 'success' : 'danger'}>
                    {new Date(mindre).toLocaleString()}
                  </Alert> */}

                {states.email && !states.disabled && (
                  <>
                    <p>Enter your new password:</p>
                    <ChangePassword state={states} handleSubmit={handleSubmit} />
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SetNewPassword;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ChangePassword = ({ state, handleSubmit, handleChange }) => {
  const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup
      .string()
      .required('Enter a password')
      .matches(
        PasswordRegex,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special character (@ $ ! % * # ? &)',
      ),
    passwordConfirmation: yup
      .string()
      .required('You have to enter a password')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      handleChange={handleChange}
      // onSubmit={async (values) => {
      //   await new Promise((r) => setTimeout(r, 500));
      //   console.log
      // }}
      initialValues={{
        email: state.email ? state.email : 'no',
        password: '',
        passwordConfirmation: '',
      }}
    >
      {({ handleChange, handleSubmit, handleBlur, values, touched, isValid, errors }) => (
        <>
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="" controlId="formEmail">
              {/* <Form.Label>Email:</Form.Label> */}
              <Form.Control type="input" className="email-link" readOnly value={state.email && state.email} />
            </Form.Group>

            <Form.Group controlId="validationFormik03">
              {/* <Form.Label>Password</Form.Label> */}
              <Form.Control
                type="password"
                placeholder="Enter new password"
                name="password"
                value={values.password}
                onChange={handleChange}
                isInvalid={!!errors.password}
                isValid={touched.password && !errors.password}
              />

              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="validationFormik05">
              {/* <Form.Label>Retype password</Form.Label> */}
              <Form.Control
                type="password"
                placeholder="Enter the same password again"
                name="passwordConfirmation"
                value={values.passwordConfirmation}
                onChange={handleChange}
                isInvalid={!!errors.passwordConfirmation}
                isValid={touched.passwordConfirmation && !errors.passwordConfirmation}
              />
              <Form.Control.Feedback type="invalid">{errors.passwordConfirmation}</Form.Control.Feedback>
            </Form.Group>

            <Button type="submit" block className="my-4" variant={isValid ? 'primary' : 'secondary'}>
              {/* {state.loading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                </>
              ) : (
                'Set Password'
              )} */}
              Change password
            </Button>
          </Form>
        </>
      )}
    </Formik>
  );
};
