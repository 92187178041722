import { gql, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const GET_DOWNLOAD_LINK = gql`
  query GetFileDownloadLink($fileId: String, $applicationId: String) {
    downloadApplicationDocument(fileId: $fileId, applicationId: $applicationId) {
      url
    }
  }
`;

const DownloadCv = ({ fileId, applicationId, className }) => {
  const [getFileUrl, { loading, error, data }] = useLazyQuery(GET_DOWNLOAD_LINK, {
    fetchPolicy: 'network-only',
  });

  const handleDownload = async (id) => {
    await getFileUrl({ variables: { fileId: id, applicationId } });
  };

  useEffect(() => {
    if (data && data.downloadApplicationDocument) {
      window.open(data.downloadApplicationDocument.url, '_blank');
    }
  }, [data]);

  return (
    <OverlayTrigger placement="auto" overlay={<Tooltip>Download CV</Tooltip>}>
      <Button variant="secondary" onClick={() => handleDownload(fileId)} className={className}>
        {loading ? (
          <FontAwesomeIcon icon={['far', 'spinner']} spin fixedWidth className="my-auto" />
        ) : (
          <FontAwesomeIcon icon={['fas', 'download']} fixedWidth className="my-auto" />
        )}
      </Button>
    </OverlayTrigger>
  );
};
export default DownloadCv;
