var accessToken = '';

export const getAccessToken = () => {
  return accessToken;
};

export const setAccessToken = (token) => {
  accessToken = token;
};

export const fetchAccessToken = () => {
  return new Promise((resolve, reject) => {
    const uri = process.env.REACT_APP_GRAPHQL_URL
      ? process.env.REACT_APP_GRAPHQL_URL + '/refresh_token'
      : 'https://graphql-mog42n2lwa-lz.a.run.app/refresh_token';
    console.log('fetch', uri);
    fetch(uri, {
      method: 'POST',
      credentials: 'include',
    })
      .then(async (x) => {
        const { accessToken } = await x.json();
        setAccessToken(accessToken);
        resolve(accessToken);
      })
      .catch((err) => {
        setAccessToken('');
        console.error(err);
        reject(err);
      });
  });
};
