import ReactDOM from 'react-dom/client';
import 'react-loading-skeleton/dist/skeleton.css';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

import { LEVEL_CRITICAL, Provider as RollbarProvider } from '@rollbar/react';
import { BetterRollbarErrorBoundary } from './BetterRollbarErrorBoundary';
import { VersionCheck } from './VersionCheck';

export const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_NODE_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: process.env.REACT_APP_NODE_ENV === 'production' || process.env.REACT_APP_NODE_ENV === 'test',
  addErrorContext: true,
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RollbarProvider config={rollbarConfig}>
    <BetterRollbarErrorBoundary level={LEVEL_CRITICAL}>
      <Router basename={process.env.PUBLIC_URL}>
        <App />
      </Router>
      <VersionCheck />
    </BetterRollbarErrorBoundary>
  </RollbarProvider>,
);
