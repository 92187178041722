import React, { useMemo, useState } from 'react';
import { faCheckCircle, faCog, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { gql, useMutation, useQuery } from '@apollo/client';
import SimpleLoader from '../../elements/SimpleLoader';
import { BULLHORN_JOB_SETTINGS_QUERY } from './queries';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
// import { DevTool } from '@hookform/devtools';

const ERROR_CODES = {
  LOADING_TEMPLATES_FAILED: 'LOADING_TEMPLATES_FAILED',
  CONFIGURE_JOB_FAILED: 'CONFIGURE_JOB_FAILED',
};

const ConfigureJobForm = ({ job, settings, onSuccess, onError }) => {
  const { loading: templatesLoading, data: getTemplatesResponse } = useQuery(
    gql`
      query Templates {
        templates {
          id
          name
          isNew
        }
      }
    `,
    { onError: (error) => onError({ code: ERROR_CODES.LOADING_TEMPLATES_FAILED, cause: error }) },
  );

  const templateOptions = useMemo(() => {
    const sortedTemplates = [...(getTemplatesResponse?.templates ?? [])].sort((a, b) => a.name.localeCompare(b.name));

    return [
      { id: '-1', name: 'Select template' }, // placeholder option
      ...sortedTemplates,
    ];
  }, [getTemplatesResponse?.templates]);

  const [configureJob, { loading: savingJobSettings }] = useMutation(
    gql`
      mutation BullhornConfigureJob(
        $templateId: String!
        $threshold: Int!
        $autoInvite: Boolean!
        $bullhornJobOrderId: Int!
      ) {
        bullhornConfigureJob(
          templateId: $templateId
          threshold: $threshold
          autoInvite: $autoInvite
          bullhornJobOrderId: $bullhornJobOrderId
        )
      }
    `,
    {
      refetchQueries: [{ query: BULLHORN_JOB_SETTINGS_QUERY, variables: { bullhornJobOrderId: job } }],
    },
  );

  const {
    register,
    // control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      template: settings?.templateId ?? '-1',
      threshold: settings?.threshold ?? 0,
      autoInvite: settings?.autoInvite ?? true,
    },
  });

  if (templatesLoading || savingJobSettings) {
    return <SimpleLoader />;
  }

  const onSubmit = (values) => {
    const configureJobRequest = {
      templateId: values.template,
      threshold: values.threshold,
      autoInvite: values.autoInvite,
      bullhornJobOrderId: job,
    };

    return configureJob({
      variables: configureJobRequest,
    })
      .then((response) => {
        reset();
        onSuccess(response);
      })
      .catch((error) => {
        reset();
        onError({ code: ERROR_CODES.CONFIGURE_JOB_FAILED, error });
      });
  };

  return (
    <div className="settings-page">
      {/* <DevTool control={control} placement="top-left" /> */}
      <form className="small configure-job-form" onSubmit={handleSubmit(onSubmit)}>
        <Form.Group>
          <Form.Label className="label-font">Template</Form.Label>
          <OverlayTrigger
            rootClose
            trigger="hover"
            placement="right"
            overlay={
              <Popover>
                <Popover.Content>The interview template that will be used for the Hubert.ai interview</Popover.Content>
              </Popover>
            }
          >
            <FontAwesomeIcon className="info-tooltip" icon={faInfoCircle} />
          </OverlayTrigger>
          <Form.Control
            type="select"
            as="select"
            {...register('template', {
              validate: { required: (value) => value !== '-1' || 'Please select a template' },
            })}
            isInvalid={errors.template}
          >
            {templateOptions // otherwise sort will thrown an error because of frozen array in strict mode
              .map(({ id, name }) => (
                <option key={id} value={id} disabled={id === '-1'} hidden={id === '-1'}>
                  {name}
                </option>
              ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">{errors.template?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label className="label-font">Threshold</Form.Label>
          <OverlayTrigger
            rootClose
            trigger="hover"
            placement="right"
            overlay={
              <Popover>
                <Popover.Content>
                  Qualifying candidates who score above this value are classified as “Leads”, while those who score
                  below this value are classified as “Passed”
                </Popover.Content>
              </Popover>
            }
          >
            <FontAwesomeIcon className="info-tooltip" icon={faInfoCircle} />
          </OverlayTrigger>
          <Form.Control
            type="number"
            {...register('threshold', {
              valueAsNumber: true,
              min: { value: 0, message: 'Please enter a threshold between 0 and 100.' },
              max: { value: 100, message: 'Please enter a threshold between 0 and 100.' },
              required: { value: true, message: 'Threshold is required.' },
            })}
            isInvalid={errors.threshold}
          />
          <Form.Control.Feedback type="invalid">{errors.threshold?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label className="label-font">Auto-invite</Form.Label>
          <OverlayTrigger
            rootClose
            trigger="hover"
            placement="right"
            overlay={
              <Popover>
                <Popover.Content>
                  If enabled, candidates will automatically be invited to a Hubert.ai interview when they apply for the
                  job in Bullhorn
                </Popover.Content>
              </Popover>
            }
          >
            <FontAwesomeIcon className="info-tooltip" icon={faInfoCircle} />
          </OverlayTrigger>
          <Form.Switch type="switch" id="btn-auto-switch" {...register('autoInvite')} />
        </Form.Group>
        <Button type="submit" block className="submit-button my-4">
          Save
        </Button>
      </form>
    </div>
  );
};

const BhWidgetSettings = ({ job }) => {
  const [renderForm, setRenderForm] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const { data: bullhornJobSettingsQuery, loading } = useQuery(BULLHORN_JOB_SETTINGS_QUERY, {
    variables: { bullhornJobOrderId: job },
  });

  if (loading) {
    return <SimpleLoader />;
  }

  if (error) {
    let errorTitle;
    let errorMessage = `It's not you, it's us. Please reload the page and try again. If the issue persists, contact Hubert support.`;

    switch (error?.code) {
      case ERROR_CODES.LOADING_TEMPLATES_FAILED:
        errorTitle = 'Unable to load Hubert interview templates';
        break;
      case ERROR_CODES.CONFIGURE_JOB_FAILED:
        errorTitle = 'Unable to configure the job';
        break;
      default:
        errorTitle = 'Something went wrong unexpectedly';
        break;
    }

    return (
      <div className="settings-page">
        <div className="settings-not-configured success">
          <div>
            <FontAwesomeIcon className="icon" icon={faTimesCircle} size="4x" />
            <div>
              <h2>{errorTitle}</h2>
              <p>{errorMessage}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (result) {
    return (
      <div className="settings-page">
        <div className="settings-not-configured success">
          <div>
            <FontAwesomeIcon className="icon" icon={faCheckCircle} size="5x" />
            <div>
              <h2>Job configured!</h2>
              <p>Settings changed successfully.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (renderForm || bullhornJobSettingsQuery?.bullhornJobSettings) {
    return (
      <ConfigureJobForm
        job={job}
        settings={bullhornJobSettingsQuery?.bullhornJobSettings}
        onSuccess={(result) => setResult(result)}
        onError={(error) => setError(error)}
      />
    );
  }

  return (
    <div className="settings-page">
      <div className="settings-not-configured success">
        <div>
          <FontAwesomeIcon className="icon" icon={faCog} size="4x" />
          <div>
            <h2>Hubert hasn't been configured yet</h2>
            <p>
              You can configure Hubert below to send invites automatically for incoming candidates for this job, or to
              send an interview manually.
            </p>
            <Button className="my-4" onClick={() => setRenderForm(true)}>
              Configure job
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BhWidgetSettings;
