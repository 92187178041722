import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';

function useInterviewActions() {
  const [executeSendInterviewInvitation, sendInterviewInvitationMutationResult] = useMutation(gql`
    mutation sendInterviewInvitation($applicationId: String!) {
      sendInterviewInvitation(applicationId: $applicationId)
    }
  `);

  return {
    sendInterviewInvitation: [
      async (applicationId) => {
        if (applicationId) {
          try {
            const response = await executeSendInterviewInvitation({
              variables: {
                applicationId,
              },
            });

            if (response.data?.sendInterviewInvitation) {
              toast.success('Success', { description: 'A new invitation has been sent.' });
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          toast.error(
            'Something went wrong',
            "It's not you, it's us. Please try again. If the issue persists, please contact Hubert support. You can use the Help tab to copy diagnostic information.",
          );
        }
      },
      sendInterviewInvitationMutationResult,
    ],
  };
}

export default useInterviewActions;
