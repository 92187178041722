import React, { useMemo, useState } from 'react';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dropdown,
  FormControl,
  OverlayTrigger,
  Popover,
  Table,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import SimpleLoader from '../../elements/SimpleLoader';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';
import { INTERVIEW_FILTER_PRESETS } from './constants';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import useInterviewActions from './useInterviewActions';

function getLabelForInterviewState(state) {
  if (!state) {
    return 'Not Started';
  }

  switch (state) {
    case 'loaded':
      return 'Opened';
    case 'started':
    case 'resume_loaded':
      return 'Started';
    case 'ended':
      return 'Ended';
    default:
      return state;
  }
}

function getLabelForInterviewStatus(status) {
  if (!status) {
    return null;
  }

  switch (status) {
    case 'high_score':
      return 'Lead';
    case 'not_qualified':
      return 'Not Qualified';
    case 'passed':
      return 'Passed';
    default:
      break;
  }
}

function getSortingPriorityByStatus(status) {
  switch (status) {
    case 'high_score':
      return 0;
    case 'passed':
      return 1;
    case 'not_qualified':
      return 2;
    default:
      return 3;
  }
}

function getCandidateName(fromApplication) {
  return (
    fromApplication.candidate.name ?? `${fromApplication.candidate.firstName} ${fromApplication.candidate.lastName}`
  );
}

const InterviewInfo = ({ interview }) => (
  <OverlayTrigger
    rootClose
    trigger="click"
    placement="right"
    overlay={
      <Popover>
        <Popover.Title as="h3">Info</Popover.Title>
        <Popover.Content>
          <p>
            Interview id: <strong>{interview.id}</strong>
          </p>
          <p>
            Interview state: <strong>{getLabelForInterviewState(interview.state)}</strong>
          </p>
          <p>
            Invited on: <strong>{interview.created_at ? new Date(+interview.created_at).toDateString() : 'N/A'}</strong>
          </p>
        </Popover.Content>
      </Popover>
    }
  >
    <FontAwesomeIcon className="icon" icon={faInfoCircle} />
  </OverlayTrigger>
);

function SearchInput({ onSearch }) {
  return (
    <div>
      <label htmlFor="interviews-list-search-input">Search candidate</label>
      <FormControl
        id="interviews-list-search-input"
        placeholder="Enter name or email"
        onChange={(e) => onSearch(e.target.value)}
      />
    </div>
  );
}

const sortByStatusAndScore = (anApplication, anotherApplication) => {
  const priorityByStatus =
    getSortingPriorityByStatus(anApplication.status) - getSortingPriorityByStatus(anotherApplication.status);

  const haveApplicationsSamePriorityByStatus = priorityByStatus === 0;

  if (haveApplicationsSamePriorityByStatus) {
    return anotherApplication.score - anApplication.score;
  }

  return priorityByStatus;
};

function Filter({ preset, recordCount, onFilterChange }) {
  return (
    <ToggleButtonGroup
      className="filter-presets-button-group"
      type="radio"
      name="filter"
      value={preset}
      onChange={onFilterChange}
    >
      <ToggleButton value={INTERVIEW_FILTER_PRESETS.LEADS_ONLY} variant="outline-primary">
        Leads only {preset === INTERVIEW_FILTER_PRESETS.LEADS_ONLY ? `(${recordCount})` : ''}
      </ToggleButton>
      <ToggleButton value={INTERVIEW_FILTER_PRESETS.QUALIFIED} variant="outline-primary">
        Qualified {preset === INTERVIEW_FILTER_PRESETS.QUALIFIED ? `(${recordCount})` : ''}
      </ToggleButton>
      <ToggleButton value={INTERVIEW_FILTER_PRESETS.ALL} variant="outline-primary">
        All {preset === INTERVIEW_FILTER_PRESETS.ALL ? `(${recordCount})` : ''}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

function InterviewsList({ applications, loading, filter, onFilterChange }) {
  const [searchQuery, setSearchQuery] = useState('');
  const {
    sendInterviewInvitation: [sendInterviewInvitation, { loading: sendInterviewInvitationLoading }],
  } = useInterviewActions();

  const sortedApplicationRows = useMemo(
    () =>
      (searchQuery
        ? applications.filter(
            (anApplication) =>
              getCandidateName(anApplication).toLowerCase().includes(searchQuery.toLowerCase()) ||
              anApplication.candidate.email?.toLowerCase().includes(searchQuery.toLowerCase()),
          )
        : [...applications]
      ).sort(sortByStatusAndScore),
    [applications, searchQuery],
  );

  if (loading || sendInterviewInvitationLoading) {
    return <SimpleLoader />;
  }

  const debouncedSearch = debounce((search) => setSearchQuery(search), 500);

  return (
    <>
      <SearchInput onSearch={debouncedSearch} />
      <Filter preset={filter} recordCount={sortedApplicationRows.length} onFilterChange={onFilterChange} />
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Info</th>
            <th>Name</th>
            <th>Score</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedApplicationRows.map((application) => {
            const candidateName = getCandidateName(application);
            const interview = application.interviews.nodes[0];

            return (
              <tr key={application.id}>
                <td>
                  <InterviewInfo interview={interview} />
                </td>
                <td>
                  <Link to={`job/${application.job.id}/${application.id}`} target="_blank" rel="noreferrer">
                    {candidateName}
                  </Link>
                </td>
                <td>{application.score}</td>
                <td>{getLabelForInterviewStatus(application.status)}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="light" title="Actions">
                      <FontAwesomeIcon icon={faEllipsisH} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        disabled={interview.state === 'ended'}
                        onClick={() => sendInterviewInvitation(application.id)}
                      >
                        Resend invite
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default InterviewsList;
