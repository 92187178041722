import React from 'react';
import SendInterviewForm from './SendInterviewForm';
import { useQuery } from '@apollo/client';
import { BULLHORN_JOB_SETTINGS_QUERY } from './queries';
import SimpleLoader from '../../elements/SimpleLoader';
import BhWidgetSettings from './BhWidgetSettings';

function SendInterviewContainer({ job }) {
  const { data: bullhornJobSettingsQuery, loading } = useQuery(BULLHORN_JOB_SETTINGS_QUERY, {
    variables: { bullhornJobOrderId: job },
  });

  if (loading) {
    return <SimpleLoader />;
  }

  if (!bullhornJobSettingsQuery?.bullhornJobSettings) {
    return <BhWidgetSettings job={job} />;
  }

  return <SendInterviewForm job={job} settings={bullhornJobSettingsQuery?.bullhornJobSettings} />;
}

export default SendInterviewContainer;
